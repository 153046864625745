import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../utility/hooks/UseStore";
import SeekPlayer from "./timelineRelated/SeekPlayer";
import video_icon from "../../../assets/icons/video.svg";
import text_icon from "../../../assets/icons/text.svg";
import button_icon from "../../../assets/icons/button.svg";
import text_to_speech_icon from "../../../assets/icons/text_to_speech.svg";
import image_icon from "../../../assets/timeline_image_icon.svg";
import close_eye_icon from "../../../assets/icons/close_eyes.svg";
import eye_icon from "../../../assets/icons/eye.svg";
import delete_icon from "../../../assets/icons/delete.svg";
import ScaleRangeInput from "./timelineRelated/ScaleRangeInput";
import TimeFrameView from "./timelineRelated/TimeFrameView";

const TimeLine = observer(({ showTimeline, setOpenDeleteModal, setElementType, setElementId }) => {

    const mainStore = useStore();
    const store = mainStore.videoStore;
    const isSelected = store.selectedElement?.id

    const percentOfCurrentTime = (store.currentTimeInMs / store.maxTime) * 100;

    const MARKINGS = [
        {
            interval: 5000,
            color: "#3B3B4F",
            size: 16,
            width: 1,
        },
        {
            interval: 1000,
            color: "#3B3B4F",
            size: 8,
            width: 1,
        },
    ];
    // this is for size finder of user desktop
    const [sizeFinder, setSizeFinder] = useState("")

    useEffect(() => {
        if (window.innerWidth) {
            updateWindowDimensions()
        }
        window.addEventListener('resize', updateWindowDimensions)
    }, [])


    function updateWindowDimensions() {
        if (window.innerWidth > 1024 && window.innerWidth < 1280) {
            setSizeFinder("lg")
        } else if (window.innerWidth > 1280 && window.innerWidth < 18000) {
            setSizeFinder("xl")
        } else if (window.innerWidth > 768 && window.innerWidth < 1024) {
            setSizeFinder("md")
        } else if (window.innerWidth > 640 && window.innerWidth < 768) {
            setSizeFinder("sm")
        } else if (window.innerWidth > 0 && window.innerWidth < 640) {
            setSizeFinder("default")
        }
    }

    const editorElements = store.editorElements?.slice()?.sort((a, b) =>  {
        const order = { video: 1, audio: 2, default: 3 };
        const aType = order[a.type] || order.default;
        const bType = order[b.type] || order.default;
        if (aType !== bType) return aType - bType;
        return a.properties.created - b.properties.created;
    })?.reverse()


    const ref = useRef(null);
    const refIsMouseDown = useRef(false);
    const [canvasSize, setCanvasSize] = useState({
        width: 50,
        height: 35,
    });

    useEffect(() => {
        // Update canvas size based on container size
        const handleResize = () => {
            if (ref.current) {
                setCanvasSize({
                    width: ref.current.parentElement?.clientWidth ?? 50,
                    height: 35,
                });
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [store.timeLineZoom]);

    useEffect(() => {
        if (ref.current) {
            const canvas = ref.current;
            const canvasWidth = ref.current.parentElement?.clientWidth || 0;
            canvas.width = canvasWidth;
            canvas.height = canvasSize.height;
            const ctx = canvas.getContext("2d");

            if (ctx) {
                // Draw background
                ctx.fillStyle = '#17171E';
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                // Set to track unique second labels
                const uniqueSeconds = new Set();

                // Draw tick marks and second labels based on props.markings
                MARKINGS.forEach((marking) => {
                    ctx.strokeStyle = marking.color;
                    ctx.lineWidth = marking.width;

                    // Draw tick marks
                    ctx.beginPath();
                    for (let i = 0; i <= store.maxTime; i += marking.interval) {
                        const x = (i / store.maxTime) * canvasWidth;
                        ctx.moveTo(x, 0);
                        ctx.lineTo(x, marking.size);

                        // Only draw label for multiples of 5
                        if (i !== 0 && i % 5000 === 0 && !uniqueSeconds.has(i)) {
                            uniqueSeconds.add(i);
                            ctx.font = "12px Arial";
                            ctx.fillStyle = marking.color;
                            ctx.textAlign = "center";
                            ctx.fillText(`${i / 1000}s`, x, marking.size + 12); // Adjust 12px below tick mark for clarity
                        }
                    }
                    ctx.stroke();
                });
            }
        }
    }, [MARKINGS, '#17171E', store.maxTime, canvasSize, store.timeLineZoom]);

    const updateFromMouseEvent = (e) => {
        const rect = ref.current?.getBoundingClientRect();
        if (rect) {
            const x = e.clientX - rect.left;
            const value = (x / Number(ref.current?.parentElement?.clientWidth)) * store.maxTime;
            const normalizedValue = Math.min(store.maxTime, Math.max(0, value));
            store.handleSeek(normalizedValue);
        }
    };

    return (
        <div className="w-[100%] flex flex-col ">
            <div className="grid grid-cols-12  border-b border-[#2C2D3C]">
                <p className="col-span-3 2xl:col-span-2 font-Inter font-normal text-sm text-[#ABABC0] px-[12px] py-[10px] w-full border-r border-[#2C2D3C] text-center h-[38px] 2xl:h-[45px]">Layers</p>
                <div className="col-span-9 2xl:col-span-10">
                    <SeekPlayer showTimeline={showTimeline} />
                </div>
            </div>
            <div className={`grid grid-cols-12 overflow-auto place-content-start duration-1000 ${showTimeline ? "h-[145px] 2xl:h-[200px] showTimeLine" : "max-h-[0px] hideTimeLine"}  scrollbar-thin scrollbar-thumb-[#5446D0] scrollbar-track-transparent`}>
                {/* element */}
                <div className=" col-span-12 grid grid-cols-12 sticky top-0 z-50 pr-[20px]">
                    <p className="bg-[#17171E] col-span-3 2xl:col-span-2 font-Inter font-normal text-sm text-[#ABABC0] px-[12px]  flex items-center justify-center w-full border-r border-b border-[#2C2D3C] text-center h-[35px]">Impact Layers</p>
                    <div style={{ width: `${store.timeLineZoom}%` }} className=" border-[#2C2D3C] col-span-9 2xl:col-span-10">
                        {/* <ScaleRangeInput
                                    max={store.maxTime}
                                    value={store.currentTimeInMs}
                                    onChange={(value) => {
                                        store.handleSeek(value);
                                    }}
                                    height={35}
                                    markings={MARKINGS}
                                    backgroundColor="#17171E"
                                    className="h-[35px]"
                                /> */}
                        <div
                            className="relative w-[100%] overflow-hidden hover:cursor-grabbing"
                            onMouseDown={(e) => {
                                refIsMouseDown.current = true;
                                updateFromMouseEvent(e);
                            }}
                            onMouseUp={() => {
                                refIsMouseDown.current = false;
                            }}
                            onMouseMove={(e) => {
                                if (refIsMouseDown.current) {
                                    updateFromMouseEvent(e);
                                }
                            }}
                            onMouseLeave={() => {
                                refIsMouseDown.current = false;
                            }}
                        >
                            <div style={{ width: `${store.timeLineZoom}` }} className="">
                                <canvas height={35} ref={ref}></canvas>
                            </div>
                            <svg style={{
                                transform: `translateX(${(store.currentTimeInMs / store.maxTime) * Number(ref.current?.parentElement?.clientWidth)
                                    }px) translateX(0.2px)`,
                            }} className="absolute top-0 left-[-1px]" width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.94302 16.8207C8.35699 18.534 5.64675 18.5305 4.06518 16.813L1.05756 13.547C0.377503 12.8085 1.19409e-06 11.8413 1.10633e-06 10.8374L5.08584e-07 4C1.26913e-06 1.79086 1.79086 -2.74734e-06 4 -2.94047e-06L10 -1.55766e-06C12.2091 -1.75079e-06 14 1.79086 14 4L14 10.8708C14 11.8783 13.6198 12.8487 12.9354 13.588L9.94302 16.8207Z" fill="white" />
                            </svg>
                            <div
                                id="pointerID"
                                className="rounded-full bg-[#FFFFFF] w-[2px] absolute top-0 left-[5px]"
                                style={{
                                    height: `${35 + 10}px`,
                                    transform: `translateX(${(store.currentTimeInMs / store.maxTime) * Number(ref.current?.parentElement?.clientWidth)
                                        }px) translateX(0.2px)`,
                                }}
                            ></div>
                        </div>
                        {/* <p className={`text-[#fff] absolute z-50 top-[5px] text-[12px] bg-[#272836] ml-5 `} style={{left: `${percentOfCurrentTime}%`,}}>{(store.currentTimeInMs / 1000).toFixed(2)}</p> */}
                    </div>
                </div>


                {/* timeline  */}
                <div className="col-span-12 pr-[20px]">
                    <div className="grid grid-cols-12">
                        <div className=" col-span-3 2xl:col-span-2 border-r border-[#2C2D3C]">
                            {editorElements.map((element) => (
                                <div
                                    className={` flex px-[12px] flex-row justify-between items-center ${isSelected === element.id ? 'bg-[#272836]' : ''} w-full  py-[0px] h-[29px] 2xl:h-[34px]`}
                                    key={element?.id}
                                    onClick={() => {
                                        store.setSelectedElement(element);
                                    }}
                                >
                                    <div className=" flex items-center">
                                <div className=" w-[20px]">
                                    {element?.type === "video" && <img src={video_icon} alt="VideoIcon" className="w-[16px] h-auto object-contain " />}
                                    {element?.type === "text" && <img src={text_icon} alt="TextIcon" className="w-[16px] h-auto object-contain " />}
                                    {element?.type === "button" && <img src={button_icon} alt="BtnIcon" className="w-[20px] h-auto object-contain " />}
                                    {element?.type === "audio" && <img src={text_to_speech_icon} alt="TTSIcon" className="w-[20px] h-auto object-contain " />}
                                    {element?.type === "image" && <img src={image_icon} alt="TTSIcon" />}
                                    </div>
                                    <div>
                                        {element && element.type === "video" ? (
                                            <video
                                                className="opacity-0 max-w-[0px] max-h-[0px]"
                                                src={element.properties.src}
                                                onLoad={() => {
                                                    store.refreshElements();
                                                }}
                                                onLoadedData={() => {
                                                    store.refreshElements();
                                                }}
                                                height={20}
                                                width={20}
                                                id={element.properties.elementId}
                                            ></video>
                                        ) : null}
                                        {element?.type === "image" ? (
                                            <img
                                                className="opacity-0 max-w-[20px] max-h-[20px]"
                                                src={element.properties.src}
                                                onLoad={() => {
                                                    store.refreshElements();
                                                }}
                                                alt="img"
                                                onLoadedData={() => {
                                                    store.refreshElements();
                                                }}
                                                height={0}
                                                width={0}
                                                id={element.properties.elementId}
                                            ></img>
                                        ) : null}
                                        {element?.type === "audio" ? (
                                            <audio
                                                className="opacity-0 max-w-[20px] max-h-[20px]"
                                                src={element.properties.src}
                                                onLoad={() => {
                                                    store.refreshElements();
                                                }}
                                                onLoadedData={() => {
                                                    store.refreshElements();
                                                }}
                                                id={element.properties.elementId}
                                            ></audio>
                                        ) : null}
                                    </div>
                                        <p className=" text-[#ababc0] text-[12px] ml-3">
                {element.type === "video" ? "Video" : element.type === "text" ? element.properties.text ? element.properties.text?.length > 15 ? element.properties.text.substring(0,15) + '...' : element.properties.text  : 'Text' : element.type === "button" ? element.properties.text ? element.properties.text?.length > 15 ? element.properties.text.substring(0,15) + '...' : element.properties.text : 'Button' : element.type === "image" ? "Image" : 'Audio'}
                                        </p>
                                    </div>
                                    <div>

                                        <button onClick={(e) => {
                                            store.isVisible(element.id, element.isVisible === true ? false : true)
                                        }}
                                            className={`${element?.type === "video" ? "cursor-not-allowed opacity-[0.2]" : "cursor-pointer"} m-[10px] 2xl:m-[10px] outline-none`}
                                            disabled={element?.type === 'video'}
                                        >
                                            {(element?.timeFrame.start >= store.maxTime) ? <img src={close_eye_icon} alt="CloseEye" className=" w-[20px] h-auto object-contain 2xl:w-auto 2xl:object-none" /> : <img src={eye_icon} className=" w-[20px] h-auto object-contain 2xl:w-auto 2xl:object-none" alt="Eye" />}

                                        </button>
                                        <button
                                            className={`${element?.type === "video" ? "cursor-not-allowed opacity-[0.2]" : "cursor-pointer"} m-[10px] 2xl:m-[10px] outline-none`}
                                            onClick={(e) => {
                                                setOpenDeleteModal(true)
                                                setElementType(element.type)
                                                setElementId(element.id);
                                                // store.refreshElements();
                                                // e.preventDefault();
                                                // e.stopPropagation();
                                                // store.setSelectedMenuOption("NoSelectedItem")
                                            }}
                                            disabled={element?.type === 'video'}
                                        >
                                            <img src={delete_icon} className=" w-[14px] h-auto object-contain 2xl:w-auto 2xl:object-none" alt="Delete" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div style={{ width: `${store.timeLineZoom}%` }} className=" col-span-9 2xl:col-span-10">
                            <div className="flex-1 relative">
                                {editorElements.map((element) => {
                                    if (element.timeFrame.start >= store.maxTime) {
                                        return (<div key={element.id} className="w-[100%] bg-[#0D0D11] h-[30px] 2xl:h-[35px]"></div>)
                                    } else {
                                        return (<TimeFrameView key={element.id} element={element} />)
                                    }
                                })}
                                <div
                                    className="w-[2px] bg-[#FFFFFF] absolute top-0 bottom-0 ml-[5px]"
                                    style={{
                                        transform: `translateX(${(store.currentTimeInMs / store.maxTime) * Number(ref.current?.parentElement?.clientWidth)
                                            }px) translateX(0.2px)`,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
});

export default TimeLine;
