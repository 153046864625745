import axios from "axios";
import { action, computed, makeAutoObservable, makeObservable, observable } from "mobx";
import toast from "react-hot-toast";
import apiService from "../configs/axios";
import { decryptData, encryptData, errorStatusHandler, isEncryptModeOn } from "../utility/utils";

import { localStorageNames } from "../configs/constants"
const { __USER_DATA , __PLAN_DATA } = localStorageNames

export class userStore {

    userData
    userDomainList
    userOpenAiAPIKeys
    encryptMode


    constructor() {
        this.userData = {}
        this.userDomainList = []
        this.userOpenAiAPIKeys = {}
        this.encryptMode = isEncryptModeOn()
        makeAutoObservable(this)
    }

    async getUserData({ loader }) {
        try {
            const userDataResponse = await apiService.get(`/user/details`)
            const finalUserData = await userDataResponse.data
            if (finalUserData.success === true) {
                this.userData = finalUserData.data
                loader(false)
                return this.userData
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                loader(false)
                toast.error(error.response.data.error)
            }
        }
    }

    async updateUserDetails({ finalData, loader , getLoginUserData }) {
        try {
            const updatedUserData = await apiService.patch(`/user/details`, finalData)
            const finalUpdatedData = await updatedUserData?.data
            if (finalUpdatedData.success === true) {
                getLoginUserData()
                toast.success(finalUpdatedData.message)
                loader(false)
                const userData = decryptData(localStorage.getItem(__USER_DATA))
                localStorage.removeItem(__USER_DATA)
                userData.name = finalData.name
                localStorage.setItem(__USER_DATA, encryptData(userData))
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response?.data?.statusCode, error.response?.data?.error)
            if (!isAuthFailed) {
                loader(false)
                toast.error(error.response?.data?.error)
            }
        }
    }

    async updateUserPasswordDetails({ headers, finalData, loader, setNewPasswordData, navigate }) {
        try {
            const response = await apiService.patch('/user/reset-password', finalData, { ...headers })
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                toast.success(finalResponse.message)
                loader(false)
                setNewPasswordData({
                    Current_Password: '',
                    New_Password: '',
                    Confirm_Password: ''
                })
                localStorage.clear()
                navigate("/login")
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response?.data?.statusCode, error.response?.data?.error)
            if (!isAuthFailed) {
                loader(false)
                toast.error(error.response?.data?.error)
            }
        }
    }

    async registration({ loader, finalData: data, navigate: navigation }) {
        try {
            let dataForSend 
            this.encryptMode ? dataForSend.data = encryptData(data) : dataForSend = data

            const registertionRequest = await apiService.post(`/user/register`, { ...dataForSend })
            const finalData = await registertionRequest.data
            if (finalData.success === true) {
                loader(false)
                toast.success(finalData.message, { duration: 3000 })
                navigation("/login")
            }
        } catch (error) {            
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                loader(false)
                toast.error(error.response.data.error)
            }
        }
    }

    async Plandetail({ loader,  navigate }) {
        try {
            const loginResponse = await apiService.get(`/user/plan-config`)
            const finalLoginData = await loginResponse.data
            if (finalLoginData.success === true) {
                localStorage.setItem(__PLAN_DATA, this.encryptMode ? JSON.stringify(finalLoginData.data) : encryptData(finalLoginData.data))
                navigate('/videos')
                loader(false)
            }
        } catch (error) {
                toast.error(error.response.data.error)
                loader(false)
        }
    }

    async GetConfig({SetCanGetOpenAIAPIFromStore}) {
        try {
            const response = await apiService.get(`/user/conf`)
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.userOpenAiAPIKeys = finalResponse.data
                SetCanGetOpenAIAPIFromStore(true)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
            }
        }
    }

    async Login({ loader, finalData: data, navigate, setVerificationPendingPopup , SetCanGetOpenAIAPIFromStore }) {
        try {
            let dataForSend 
            this.encryptMode ? dataForSend.data = encryptData(data) : dataForSend = data

            const loginResponse = await apiService.post('/user/login', { ...dataForSend })
            const finalLoginData = await loginResponse.data
            if (finalLoginData.success === true) {
                localStorage.setItem(__USER_DATA, this.encryptMode ? JSON.stringify(finalLoginData.data) : encryptData(finalLoginData.data))
                toast.success(finalLoginData.message)
                this.Plandetail({ loader , navigate})
                this.GetConfig({ SetCanGetOpenAIAPIFromStore})
                loader(false)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                loader(false)
                if (error.response.data.error === "Email verification pending.") {
                    setVerificationPendingPopup(true)
                }
                toast.error(error.response.data.error)
            }

        }
    }


    async EmailVerify(authToken, loader, setUserName, setVerificationSuccessful) {
        try {
            const verifyEmailResponse = await apiService.get(`/user/verify-email?authToken=${authToken}`)
            const verifiedEmailResponse = await verifyEmailResponse.data
            if (verifiedEmailResponse.success === true) {
                toast.success(verifiedEmailResponse.message)
                setUserName(verifiedEmailResponse.name)
                loader(false)
                setVerificationSuccessful(true)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.error, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
            }
            setVerificationSuccessful(false)
            loader(false)
        }
    }

    async ForgetPasswordRequest(data, navigate) {
        try {
            const ForgetPasswordRequestResponse = await apiService.post('/user/forgot-password-req', data)
            const finalEmailRequestResponse = await ForgetPasswordRequestResponse.data
            if (finalEmailRequestResponse.success === true) {
                toast.success(finalEmailRequestResponse.message)
                navigate('/login')
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
            }
        }
    }

    async setNewPassword({ token, finalData, navigate, loader }) {
        try {
            const setNewPasswordResponse = await apiService.patch(`/user/forgot-password?token=${token}`, finalData)
            const finalSetNewPasswordResponse = await setNewPasswordResponse.data
            if (finalSetNewPasswordResponse.success === true) {
                toast.success(finalSetNewPasswordResponse.message)
                navigate('/login')
                loader(false)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
                loader(false)
            }
        }
    }

    async resendEmail({ finalData, loader, setVerificationPendingPopup, setLoginData }) {
        try {
            const resendEmailResponse = await apiService.patch(`/user/verifymail-resend`, finalData)
            const finalResendEmailResponse = await resendEmailResponse.data
            if (finalResendEmailResponse.success === true) {
                toast.success(finalResendEmailResponse.message)
                loader(false)
                setVerificationPendingPopup(false)
                setLoginData({
                    email: '',
                    password: '',
                })
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
                loader(false)
            }
        }
    }

    async getListOfDomain({ userID, SetCanGetDataFromStore }) {
        try {
            const response = await apiService.get(`/user/domain/${userID}`)
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.userDomainList = finalResponse.data
                SetCanGetDataFromStore(true)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
            }
        }
    }

    async addDomain({ finalData, SetCanGetDataFromStore, loader, setDataForNewDomain, setRunValidator }) {
        try {
            const response = await apiService.post(`/user/domain/`, finalData)
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.getListOfDomain({ userID: finalData.userId, SetCanGetDataFromStore })
                toast.success(finalResponse.message)
                loader(false)
                setDataForNewDomain({
                    domain: ""
                })
                setRunValidator(false)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
                loader(false)
            }
        }
    }

    async deleteDomain({ userID, domainID, SetCanGetDataFromStore, loader }) {
        try {
            const response = await apiService.delete(`/user/domain/${domainID}`)
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.getListOfDomain({ userID: userID, SetCanGetDataFromStore })
                loader(false)
                toast.success(finalResponse.message)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
                loader(false)
            }
        }
    }

    async updateDomainStatus({ userID, finalData, domainID, SetCanGetDataFromStore, loader, setStatusUpdateModel }) {
        try {
            const response = await apiService.patch(`/user/domain/${domainID}`, finalData)
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.getListOfDomain({ userID, SetCanGetDataFromStore })
                loader(false)
                setStatusUpdateModel(false)
                toast.success(finalResponse.message)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
                loader(false)
            }
        }
    }

    async getListOfOpenIaAPI({ SetCanGetOpenAIAPIFromStore }) {
        try {
            const response = await apiService.get(`/user/conf`)
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.userOpenAiAPIKeys = this.encryptMode ? decryptData(finalResponse.data) : finalResponse.data
                SetCanGetOpenAIAPIFromStore(true)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
            }
        }
    }

    async addOpenAiAPI({ finalData : data, SetCanGetOpenAIAPIFromStore, loader, setApiKey, setRunValidatorForOpenAI, setNewOpenAiApiModel }) {
        try {
            let dataForSend 
            this.encryptMode ? dataForSend.data = encryptData(data) : dataForSend = data
            const response = await apiService.post(`/user/conf/openai`, {...dataForSend})
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.getListOfOpenIaAPI({ SetCanGetOpenAIAPIFromStore })
                toast.success(finalResponse.message)
                loader(false)
                setNewOpenAiApiModel(false)
                setApiKey('')
                setRunValidatorForOpenAI(false)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
                loader(false)
            }
        }
    }

    async updateOpenAiStatus({ finalData : data, SetCanGetOpenAIAPIFromStore, loader, setStatusUpdateModelOpenAi }) {
        try {
            let dataForSend 
            this.encryptMode ? dataForSend.data = encryptData(data) : dataForSend = data
            const response = await apiService.patch(`/user/conf/openai`, {...dataForSend})
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.getListOfOpenIaAPI({  SetCanGetOpenAIAPIFromStore })
                loader(false)
                setStatusUpdateModelOpenAi(false)
                toast.success(finalResponse.message)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
                loader(false)
            }
        }
    }

    async deleteOpenAiAPI({ SetCanGetOpenAIAPIFromStore, loader }) {
        try {
            const response = await apiService.delete(`/user/conf/openai`)
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.getListOfOpenIaAPI({  SetCanGetOpenAIAPIFromStore })
                loader(false)
                toast.success(finalResponse.message)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
                loader(false)
            }
        }
    }
}