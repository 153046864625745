export function manageAudio(filePath, playAudio) {
  let audioElement = document.getElementById("hypercliptts_demo_voices_player");
  if (!playAudio) { 
    if (audioElement) {
      audioElement.pause();
      audioElement.src = `https://videolive.b-cdn.net/hyperclipTTS_voice${filePath}`;
      audioElement.load();
      audioElement.play();
    } else {
      audioElement = document.createElement("audio");
      audioElement.id = "hypercliptts_demo_voices_player";
      audioElement.src = `https://videolive.b-cdn.net/hyperclipTTS_voice${filePath}`;
      audioElement.autoplay = true;
      document.body.appendChild(audioElement);
      audioElement.play();
    }
  } else {
    audioElement.pause();
  }
}
