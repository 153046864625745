import { lazy } from "react"
import VerifyEmail from "../../../pages/auth/VerifyEmail"
import PublicRoute from "../../../components/routeTypes/PublicRoute"
import AddNewPassword from "../../../pages/auth/AddNewPassword"
const LoaderRing = lazy(() => import('../../../components/ui/common/LoaderRing'))
const Register = lazy(() => import('../../../pages/auth/Register'))
const AuthLayout = lazy(() => import('../../../layout/AuthLayout'))
const Login = lazy(() => import('../../../pages/auth/Login'))
const ForgetPassword = lazy(() => import('../../../pages/auth/ForgetPassword'))


const authRoute = [
    {
        path: '/login',
        element: <PublicRoute><AuthLayout><Login /></AuthLayout></PublicRoute>,
        loader: <LoaderRing />,
    },
    {
        path: '/register',
        element: <PublicRoute><AuthLayout><Register /></AuthLayout></PublicRoute>,
        loader: <LoaderRing />,
    },
    {
        path: '/verify-email',
        element: <VerifyEmail />,
        loader: <LoaderRing />
    },
    {
        path: '/addnewpassword',
        element: <AddNewPassword />,
        loader: <LoaderRing />
    },
    {
        path: '/forgotpassword',
        element: <PublicRoute><AuthLayout><ForgetPassword /></AuthLayout></PublicRoute>,
        loader: <LoaderRing />
    },
    {
        path: '/verification-pending',
        element: <PublicRoute></PublicRoute>
    }
]

export default authRoute