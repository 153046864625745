import React, { useEffect, useRef, useState } from 'react'
import { useStore } from '../../../../utility/hooks/UseStore';
import toast from 'react-hot-toast';
import useOutsideClick from '../../../../utility/hooks/useOutsideClick';
import APILoaderRing from "../../../ui/common/APILoaderRing"
import merge_list_icon from '../../../../assets/icons/mergeMenu.svg'
import add_icon from '../../../../assets/icons/add.svg'
import search_icon from '../../../../assets/icons/search.svg'
import play_icon from '../../../../assets/icons/play.svg'
import pause from '../../../../assets/pause.png'
import reset_icon from '../../../../assets/icons/reset.svg'
import playbutton from '../../../../assets/play-button.png'
import droparrow from '../../../../assets/dropdown-arrow.svg'
import { formatTimeToMinSec } from '../../../../utility/videoEditorUtils';
import SpeechSidePanel from './SpeechSidePanel';
import { observer } from 'mobx-react';
import APICallLoader from '../../../../components/ui/common/APILoaderRing'
import { useParams } from 'react-router-dom';
import { localStorageNames } from "../../../../configs/constants"
import Select, { components } from "react-select";
import { hyperclipTTS } from '../../../../configs/hyperclipTTS'
import { manageAudio } from '../../../../utility/hyperclipTTS';
const { __USER_DATA } = localStorageNames



const SpeechPanel = () => {

    const mergeTagsPopupRef = useRef(null);
    const [inputValue, setInputValue] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const [mergDropDown, setMergDropDown] = useState(false);
    const mainStore = useStore()
    const userStore = mainStore.userRegister
    const store = mainStore.videoStore
    const ref = React.useRef(null);


    const { id } = useParams()

    const [hyperclipTTSconfig, setHyperclipTTSconfig] = useState({
        language: "",
        gender: "",
        dataset: "",
        speaker: "",
        text: ""
    })

    const handlePopup = (item) => {
        setInputValue((prevValue) =>
            prevValue ? prevValue + ` {{${item}}}` : `{{${item}}}`
        );
        const newText = hyperclipTTSconfig.text + ` {{${item}}}`;
        setHyperclipTTSconfig({
            ...hyperclipTTSconfig, text : newText
        })
        setPopupVisible(false);
        setMergDropDown(false);
    };

    const handleDoubleClick = () => {
        if (headers !== undefined) {
            setPopupVisible(true);
        } else {
            toast.error("please upload excel file first")
        }
    };

    const handleFileChange = (event) => {
        // Handle file change logic
    };

    const handleDocumentClick = (event) => {
        if (
            mergeTagsPopupRef.current &&
            !mergeTagsPopupRef.current.contains(event.target)
        ) {
            setPopupVisible(false);
        }
    };


    useOutsideClick(ref, () => {
        if (mergDropDown) {
            setMergDropDown(false)
        }
    })

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    // const dispatch = useDispatch();
    const [language, setLanguage] = useState("");
    const [voice, setVoice] = useState("");
    const [voiceType, setVoiceType] = useState("");
    const [Actorvoice, setActorVoice] = useState("");
    const [Actors, setActors] = useState(false)
    const [apiCallLoader, setApiCallLoader] = useState(false)
    const [formatedAudioLength, setFormatedAudioLength] = React.useState("00:00");
    const [speed, setSpeed] = useState(1);
    const [volume, setVolume] = useState(100);
    const [screen, setScreen] = useState("speech")
    const [startValue, setStartValue] = useState("");
    const [error, setError] = useState('');
    const [time, setTime] = useState({
        minutes: '',
        seconds: '',
        milliseconds: '',
    });

    const handleRangeChange = (e) => {
        setSpeed(e.target.value);
    };

    const handleVolumeChange = (e) => {
        setVolume(parseInt(e.target.value));
    };

    const handleMinusClick = () => {
        if (volume > 0) {
            setVolume(Volume => Volume - 1);
        }
    };

    const handlePlusClick = () => {
        if (volume < 100) {
            setVolume(Volume => Volume + 1);
        }
    };


    const handleInputChange = (event) => {

        const { name, value } = event.target;
        setTime((prevTime) => ({
            ...prevTime,
            [name]: value,
        }));

    };

    // const XmlData = useSelector(state => state?.excelFile?.addFile?.data?.excelID)
    const [excelId, setExcelId] = useState("")

    useEffect(() => {
        store.ExcelListForSingleCampaign.map((item) => {
            setExcelId(item.excelId);
        })
    }, [])


    const calculateEstimatedAudioDuration = (text, speed) => {
        const averageCharactersPerSecond = 15; // Rough estimate, adjust this value based on testing
        const totalCharacters = text?.length;
        const estimatedDurationInSeconds = totalCharacters / averageCharactersPerSecond / speed;
        return estimatedDurationInSeconds;
    };

    const handleAddButtonClick = (e) => {
        e?.preventDefault();
        // Calculate total milliseconds from the input values
        const totalMilliseconds =
            Number(time.minutes) * 60000 +
            Number(time.seconds) * 1000 +
            Number(time.milliseconds);
        store.startedTime = totalMilliseconds;

        const estimatedAudioDuration = calculateEstimatedAudioDuration(engine === 'hyperclip' ? hyperclipTTSconfig.text : inputValue, speed);

        // Check if the total milliseconds exceed the video duration
        if (estimatedAudioDuration  > store.videoDuration) {
            toast.error('Text-to-speech content must not exceed video length.');
            setError('The entered time exceeds the video duration.');
        } else {
            setError('');
            setApiCallLoader(true)

            if (engine === 'hyperclip') {

                let languageCode, dataset, isDefault, speaker;
                
                hyperclipTTS.map((ele, i) => {
                    if (ele.language === hyperclipTTSconfig.language) {
                        languageCode = ele.code
                        dataset = ele.speakers.find(ele => ele.name === hyperclipTTSconfig.speaker).dataset
                        isDefault = ele.speakers.find(ele => ele.name === hyperclipTTSconfig.speaker).isDefault
                        if(ele.speakers.find(ele => ele.name === hyperclipTTSconfig.speaker).isDefault === false){
                            speaker = ele.speakers.find(ele => ele.name === hyperclipTTSconfig.speaker).speaker
                        }else {
                            speaker = ""
                        }
                    }
                })

                const finaldata = {
                    engine: 'hyperclip',
                    language: languageCode,
                    dataset: dataset,
                    isDefault: isDefault,
                    speaker: speaker,
                    text: hyperclipTTSconfig.text,
                    created : new Date()?.getTime()
                }

                store.textToSpeech({ campaignID :  id ,  finaldata, setApiCallLoader, setEngine  })
                setHyperclipTTSconfig({ text : ''})

            } else if (engine === 'openai') {
                const finaldata = {
                    engine: 'openai',
                    text: inputValue,
                    voice: voice,
                    speed: speed,
                    language: language,
                    created : new Date()?.getTime()
                }
                store.textToSpeech({ campaignID :  id ,finaldata, setApiCallLoader, setEngine })
                // dispatch(TextToSpeech({ inputValue, voice, language, speed, volume, excelId: excelId }));
                // dispatch(SpeechLoader())
                setVolume(100)
                setVoice("")
                setLanguage("")
                setInputValue('')
            }

        }

    };
    // const headers = useSelector((state) => state?.excelFile?.addFile?.data?.selectedHeaders);
    const headers = store.singleCampaignData.headers
    // const head = headers

    const audioData = []
    useEffect(() => {
        if (audioData) {            
            // store.addtexttospeech(audioData, inputValue);
        }
    }, [audioData])

    useEffect(() => {
        // If audioData chanaddtexttospeechges, update the audio source
        if (audioData) {
            const audioElement = document.getElementById('audioPlayer');
            if (audioElement) {
                // audioElement.src = `https://hooc.b-cdn.net/${audioData}`;
                audioElement.src = `${audioData}`;
            }
        }
    }, [audioData]);

    const [isDrag, setIsDrag] = useState(false)

    // this logic is making for the adding multiple TTS
    const [engine, setEngine] = useState('')


 

    const handleChangeHyperclipTTSconfig = (e) => {
        setHyperclipTTSconfig({
            ...hyperclipTTSconfig,
            [e.target.name]: e.target.value
        })
    }

    const handleDoubleClickOnHyperText = () => {
        if (headers !== undefined) {
            setPopupVisible(true);
        } else {
            toast.error("please upload excel file first")
        }
    };

    const handlePopupHyperText = (item) => {
        setHyperclipTTSconfig((prevValue) => {
            const newText = prevValue.text ? prevValue.text + `{{${item}}}` : `{{${item}}}`
            return {
                ...prevValue,
                text: newText
            }
        });
        setPopupVisible(false);
        setMergDropDown(false);
    };

    // select voice section
    const [voiceOption, setVoiceOption] = useState([]);
    const [playAudio, setPlayAudio] = useState(false);
    const [audioId, setAudioId] = useState("");
    const handlePlayClick = (event, value) => {
        event.stopPropagation(); // Prevents the click event from selecting the option
        setAudioId(value);
        manageAudio(value,playAudio);
    };
    const customStyles = {
        control: (base) => ({
          ...base,
          // border : 1,
          boxShadow: "none",
          minHeight: 41,
          border: "none !important",
          borderColor: "none !important",
          backgroundColor: "#3B3B4F",
          color: "#fff !important",
          borderRadius: 0,
        }),
        menu: (provided) => ({
          ...provided,
          boxShadow: "none",
          zIndex: "9999 !important",
          color: "#fff !important",
        }),
        menuList: (base) => ({
          ...base,
          color: "#FFFFFF", 
          backgroundColor: "#1c1c26",
          minHeight: 90,  // Set the minimum height to 100px
        maxHeight: 90,  // Set the maximum height to 200px
        overflow: 'auto',
          "::-webkit-scrollbar": {
            width: "5px",
          },
          "::-webkit-scrollbar-track": {
            background: "#272836",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#5446d0",
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: "#DAE9FF",
          },
        }),
        option: (provided) => ({
          ...provided,
          color: "#fff !important",
          fontWeight: 400,
          fontSize: 14,
          cursor: "pointer",
          borderBottom: 'none !important',
          zIndex: 9999,
          paddingTop: 5,
          paddingBottom: 5,
          borderColor: "#DAE9FF",
          borderStyle: "solid",
          ":hover": {
            backgroundColor: "#1967d2 !important",
            color: "#fff !important",
          },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'white',
            fontSize: 14,
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'white', // Change the placeholder color to white
            fontSize: 14,
        }),
        dropdownIndicator: () => ({
            display: 'none', // Hide the dropdown arrow
        }),
        indicatorSeparator: () => ({
            display: 'none', // Hide the separator line
        }),
      };
      const customOption = (props) => {

        return (
          <div
            onClick={(e) => {
              if (!e.target.classList.contains('play-button')) {
                props.innerProps.onClick(e); // Let the normal select action happen when not clicking the button
              }
            }}
            {...props.innerProps}
            className="custom-option flex items-center cursor-pointer hover:bg-[#1967d2] justify-between px-3 py-1"
          >
            <span className=' cursor-pointer'>{props.data.label}</span>
            <button
              className="play-button bg-[#5446d0] p-[3px]"
              onClick={(e) => {handlePlayClick(e, props.data.value)
                if (playAudio && audioId === props.data.value) {
                  setPlayAudio(false);
                }else{
                  setPlayAudio(true);
                }
              }}
            >
            <img src={playAudio && audioId === props.data.value ? pause : playbutton} alt="Play" className=' w-[16px] h-auto object-contain' />
            </button>
          </div>
        );
      };

    useEffect(() => {
        const con = hyperclipTTS.find(ele => ele.language === hyperclipTTSconfig.language)?.speakers?.filter(c => c.gender === hyperclipTTSconfig.gender)
        ?.map(c => ({
            value: c.sample,
            label: c.name
        }));
        setVoiceOption(con);
      }, [hyperclipTTSconfig.gender]);

    const openaikey =  userStore?.userOpenAiAPIKeys?.openAI
    return (
        <>
            <div className={`${apiCallLoader ? 'flex' : 'hidden'} fixed top-0 left-0 overflow-hidden justify-center items-center z-[30]`}>
                <APICallLoader />
            </div>
            {screen === "speech" &&
                <div className='overflow-hidden'>
                    {/* <h1 className="font-Inter font-semibold text-lg px-[10px] 2xl:px-[20px] pt-[10px] 2xl:pt-[20px] text-[#FFFFFF]">Text To Speech</h1> */}
                    <div className="p-[10px] 2xl:p-[20px]">
                        <h1 className="font-Inter font-medium text-sm text-[#ABABC0]"> Select Engine</h1>
                        <div className=' relative'>
                            <select
                                id="languageSelector"
                                className="bg-[#3B3B4F] p-[10px] cursor-pointer text-[14px] w-full mt-[16px] text-[#FFFFFF] outline-none"
                                value={engine}
                                onChange={(e) => setEngine(e.target.value)}
                            >
                                <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="">Select TTS</option>
                                <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="hyperclip">HyperclipTTS</option>
                              {openaikey?.status &&  <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="openai">Open AI TTS</option>}
                            </select>
                            <img src={droparrow} alt='droparrow' className=' absolute right-2 top-[60%] transform translate-x-[-50%]'></img>
                        </div>
                    </div>


                    {engine === 'hyperclip' && (
                        <form onSubmit={handleAddButtonClick}>
                            {/* text  */}
                            <div className="p-[10px] 2xl:p-[20px]">
                                <div className="flex justify-between items-center relative">
                                    <h3 className="font-Inter font-medium text-sm text-[#ABABC0]">Text</h3>
                                    <div className='flex justify-center font-Inter font-medium text-[#ABABC0] text-[14px] cursor-pointer' onClick={() => {
                                        setMergDropDown(true)
                                    }} > <p className='mr-[10px]'>{`{{ Variable }}`}</p>
                                        <img src={merge_list_icon} alt="MergList" />
                                    </div>

                                    {mergDropDown && headers?.length > 0 && (
                                        <div ref={ref} className='bg-[#1C1C26] absolute z-[1] max-h-[200px] overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent top-[25px] right-[0px] text-[#ABABC0] rounded-[6px] gap-[5px] p-[10px]' >
                                            {headers && headers?.map((employee, index) => (
                                                <div key={index} onClick={() => handlePopup(employee)} className="cursor-pointer p-[5px] hover:bg-[#3B3B4F]">
                                                    {employee}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="relative">
                                    <textarea
                                        value={hyperclipTTSconfig.text}
                                        onChange={(e) => handleChangeHyperclipTTSconfig(e)}
                                        name='text'
                                        className="bg-[#3B3B4F] rounded-[4px] p-[8px] 2xl:p-[10px] h-[60px] 2xl:h-[100px] w-full mt-[16px] text-[#FFFFFF] outline-none"
                                        onDoubleClick={(e) => { handleDoubleClickOnHyperText(e) }}
                                        placeholder="Please enter text"
                                        required
                                    />
                                    {popupVisible && headers?.length > 0 && (
                                        <div ref={mergeTagsPopupRef} className='bg-[#1C1C26] absolute  text-[#ABABC0] rounded-[6px] gap-[5px] p-[10px]'>
                                            {headers && headers?.map((employee, index) => (
                                                <div key={index} onClick={() => handlePopupHyperText(employee)} className="cursor-pointer p-[5px] hover:bg-[#3B3B4F]">
                                                    {employee}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <hr className="bg-[#3B3B4F] border-0  h-[1px]" />
                            {/* language */}
                            <div className="p-[10px] 2xl:p-[20px]">
                                <h1 className="font-Inter font-medium text-sm text-[#ABABC0]">Language</h1>
                                <div className=' relative'>
                                    <select id="languageSelector" required className="bg-[#3B3B4F] p-[10px] cursor-pointer text-[14px] w-full mt-[16px] text-[#FFFFFF] outline-none capitalize" name='language' value={hyperclipTTSconfig.language} onChange={(e) => handleChangeHyperclipTTSconfig(e)}>
                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="">Select Language</option>
                                        {hyperclipTTS.map((ele, i) => {
                                            return (
                                                <option className='bg-[#1C1C26] hover:bg-[#3B3B4F] capitalize' value={ele.language}> {ele.flag} {ele.language}</option>
                                            )
                                        })
                                        }
                                    </select>
                                    <img src={droparrow} alt='droparrow' className=' absolute right-2 top-[60%] transform translate-x-[-50%]'></img>
                                </div>
                            </div>
                            <hr className="bg-[#3B3B4F] border-0  h-[1px]" />
                            {/* Gender */}
                            {hyperclipTTS && hyperclipTTS?.some(ele => ele.language === hyperclipTTSconfig.language) &&
                                (<>
                                    <div className="p-[10px] 2xl:p-[20px]">
                                        <h1 className="font-Inter font-medium text-sm text-[#ABABC0]">Gender</h1>
                                        <div className=' relative'>
                                            <select id="languageSelector" required className="bg-[#3B3B4F] p-[10px] cursor-pointer text-[14px] w-full mt-[16px] text-[#FFFFFF] outline-none" name='gender' value={hyperclipTTSconfig.gender} onChange={(e) => handleChangeHyperclipTTSconfig(e)}>
                                                <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="">Select Gender</option>
                                                {hyperclipTTS?.find(ele => ele.language === hyperclipTTSconfig.language)?.isMaleAvailable &&
                                                    (
                                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="male">Male</option>
                                                    )
                                                }
                                                {hyperclipTTS?.find(ele => ele.language === hyperclipTTSconfig.language)?.isFemaleAvailable &&
                                                    (
                                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="female">Female</option>
                                                    )
                                                }
                                            </select>
                                            <img src={droparrow} alt='droparrow' className=' absolute right-2 top-[60%] transform translate-x-[-50%]'></img>
                                        </div>
                                    </div>
                                    <hr className="bg-[#3B3B4F] border-0  h-[1px]" />
                                </>)
                            }

                            {/* Voices */}
                            {hyperclipTTSconfig?.language?.length > 0 && (hyperclipTTSconfig.gender === 'male' || hyperclipTTSconfig.gender === "female") &&
                                (
                                    <>
                                        <div className="p-[10px] 2xl:p-[20px] w-full">
                                            <h1 className="font-Inter font-medium text-sm text-[#ABABC0]">Voices</h1>
                                            <div className=' relative mt-[16px]'>
                                                {/* <select id="languageSelector" className="bg-[#3B3B4F] p-[10px] cursor-pointer text-[14px] w-full mt-[16px] text-[#FFFFFF] outline-none" name='speaker' value={hyperclipTTSconfig.speaker} onChange={(e) => handleChangeHyperclipTTSconfig(e)}>
                                                    <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="">Select Voice</option>
                                                    {hyperclipTTS.find(ele => ele.language === hyperclipTTSconfig.language)?.speakers.map((i_ele, i) => {
                                                        if (i_ele.gender === hyperclipTTSconfig.gender) {
                                                            return (
                                                                <option className='bg-[#1C1C26] hover:bg-[#3B3B4F] flex justify-between' value={i_ele.name}>
                                                                {i_ele.name}
                                                                <p className=' cursor-pointer ml-5'>&#9654;</p>
                                                                </option>
                                                            )
                                                        }
                                                    })
                                                    }
                                                </select> */}
                                                {/* <img src={droparrow} alt='droparrow' className=' absolute right-2 top-[60%] transform translate-x-[-50%]'></img> */}
                                                <Select
                                                closeMenuOnSelect={true}
                                                name="speaker"
                                                menuPlacement="auto"
                                                required
                                                options={voiceOption}
                                                value={
                                                  voiceOption && voiceOption?.find((ele) => ele.label === hyperclipTTSconfig.speaker)}
                                                styles={customStyles}
                                                placeholder="Select Voice"
                                                onChange={(options) => {
                                                    setHyperclipTTSconfig({ ...hyperclipTTSconfig, speaker : options?.label});
                                                }}
                                                components={{
                                                Option: customOption,
                                                }}
                                            />
                                                <img src={droparrow} alt='droparrow' className=' absolute right-2 top-[45%] transform translate-x-[-50%]'></img>
                                            </div>
                                        </div>
                                        <hr className="bg-[#3B3B4F] border-0  h-[1px]" />
                                    </>
                                )
                            }
                            {/* Add Button  */}
                            <div className="flex justify-center px-[10px] 2xl:px-[20px] py-[20px]">
                                <button type='submit' className="py-[10px] rounded w-full bg-[#5446D0] font-Inter font-medium text-sm text-[#FFFFFF] mx-auto outline-none">
                                    Add
                                </button>
                            </div>
                        </form>
                    )

                    }
                    {engine === 'openai' && (
                        <form onSubmit={handleAddButtonClick}>
                        <div>
                            {/* text  */}
                            <div className="p-[10px] 2xl:p-[20px]">
                                <div className="flex justify-between items-center relative">
                                    <h3 className="font-Inter font-medium text-sm text-[#ABABC0]">Text</h3>
                                    <div className='flex justify-center font-Inter font-medium text-[#ABABC0] text-[14px] cursor-pointer' onClick={() => {
                                        setMergDropDown(true)
                                    }} >
                                        <p className='mr-[10px]'>{`{{ Variable }}`}</p>
                                        <img src={merge_list_icon} alt="MergList" />
                                    </div>

                                    {mergDropDown && headers?.length > 0 && (
                                        <div ref={ref} className='bg-[#1C1C26] absolute z-[1] max-h-[200px] overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent top-[25px] right-[0px] text-[#ABABC0] rounded-[6px] gap-[5px] p-[10px]' >
                                            {headers && headers?.map((employee, index) => (
                                                <div key={index} onClick={() => handlePopup(employee)} className="cursor-pointer p-[5px] hover:bg-[#3B3B4F]">
                                                    {employee}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="relative">
                                    <textarea
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)}
                                        className="bg-[#3B3B4F] rounded-[4px] p-[8px] 2xl:p-[10px] h-[60px] 2xl:h-[100px] w-full mt-[16px] text-[#FFFFFF] outline-none"
                                        onDoubleClick={handleDoubleClick}
                                        placeholder="Default Text"
                                        required
                                    />
                                    {popupVisible && headers?.length > 0 && (
                                        <div ref={mergeTagsPopupRef} className='bg-[#1C1C26] absolute  text-[#ABABC0] rounded-[6px] gap-[5px] p-[10px]'>
                                            {headers && headers?.map((employee, index) => (
                                                <div key={index} onClick={() => handlePopup(employee)} className="cursor-pointer p-[5px] hover:bg-[#3B3B4F]">
                                                    {employee}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <hr className="bg-[#3B3B4F] border-0  h-[1px]" />

                            {/* language  */}
                            <div className="p-[10px] 2xl:p-[20px]">

                                <h1 className="font-Inter font-medium text-sm text-[#ABABC0]"> Language</h1>
                                <div className=' relative'>
                                    <select
                                        id="languageSelector"
                                        className="bg-[#3B3B4F] p-[10px] cursor-pointer text-[14px] w-full mt-[16px] text-[#FFFFFF] outline-none"
                                        value={language}
                                        onChange={(e) => setLanguage(e.target.value)}
                                        required
                                    >
                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="">Select Language</option>
                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="english">English</option>
                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="hindi">Hindi</option>
                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="chinese">Chinese HK</option>
                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="french">French(Canada)</option>
                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="marathi">Marathi</option>
                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="tamil">Tamil</option>
                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="urdu">Urdu</option>
                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="nepali">Nepali</option>
                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="russian">Russian</option>
                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="korean">Korean</option>
                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="japanese">Japanese</option>
                                    </select>
                                    <img src={droparrow} alt='droparrow' className=' absolute right-2 top-[60%] transform translate-x-[-50%]'></img>
                                </div>
                            </div>
                            <hr className="bg-[#3B3B4F] border-0  h-[1px]" />
                              <div className="p-[10px] 2xl:p-[20px]">

                            <h1 className="font-Inter font-medium text-sm text-[#ABABC0]">Voices</h1>
                            <div className=' relative'>
                                <select
                                    id="languageSelector"
                                    className="bg-[#3B3B4F] p-[10px] cursor-pointer text-[14px] w-full mt-[16px] text-[#FFFFFF] outline-none"
                                    value={voice}
                                    onChange={(e) => setVoice(e.target.value)}
                                    required
                                >
                                    <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="">Select Voices</option>
                                    <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="alloy">Alloy (Male)</option>
                                    <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="echo">Echo (Male)</option>
                                    <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="fable">Fable (Male)</option>
                                    <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="onyx">Onyx (Male)</option>
                                    <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="nova">Nova (Female)</option>
                                    <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="shimmer">Shimmer (Female)</option>
                                </select>
                                <img src={droparrow} alt='droparrow' className=' absolute right-2 top-[60%] transform translate-x-[-50%]'></img>
                            </div>
                            </div>
                            <hr className="bg-[#3B3B4F] border-0  h-[1px]" />


                            {/* speed  */}
                            <div className="p-[10px] 2xl:p[20px]">
                                <div className="flex justify-between items-center pb-[10px] 2xl:pb-[16px]">
                                    <h3 className="text-[#ABABC0] font-Inter font-medium text-[14px] ">Speed</h3>
                                    <button onClick={() => setSpeed(1)} className="flex gap-[6px] items-center outline-none">
                                        <img src={reset_icon} alt="Reset" />
                                        <span className="font-Inter font-medium text-xs text-[#FFFFFF]">Reset</span>
                                    </button>
                                </div>
                                <div className="flex justify-between items-center pb-[10px] w-[100%] ">
                                    <p className="font-Inter font-medium text-[#FFFFFF] text-[10px] 2xl:text-[12px] text-start">0.50</p>
                                    <p className="font-Inter font-medium text-[#FFFFFF] text-[10px] 2xl:text-[12px] text-center">0.75</p>
                                    <p className="font-Inter font-medium text-[#FFFFFF] text-[10px] 2xl:text-[12px] text-center">1.00</p>
                                    <p className="font-Inter font-medium text-[#FFFFFF] text-[10px] 2xl:text-[12px] text-center">1.25</p>
                                    <p className="font-Inter font-medium text-[#FFFFFF] text-[10px] 2xl:text-[12px] text-center">1.50</p>
                                    <p className="font-Inter font-medium text-[#FFFFFF] text-[10px] 2xl:text-[12px] text-center">1.75</p>
                                    <p className="font-Inter font-medium text-[#FFFFFF] text-[10px] 2xl:text-[12px] text-end">2.00</p>
                                </div>
                                <input
                                    type="range"
                                    className={`bg-[#ABABC0] w-full ${isDrag ? "cursor-grabbing" : "cursor-grab"}`}
                                    value={speed}
                                    onMouseDown={(e) => { setIsDrag(true) }}
                                    onMouseUp={(e) => { setIsDrag(false) }}
                                    onChange={handleRangeChange}
                                    step="0.25"
                                    min={0.50}
                                    max={2}
                                />
                            </div>
                            <hr className="bg-[#3B3B4F] border-0  h-[1px] " />


                            {/* Add Button  */}
                            <div className="flex justify-center px-[10px] 2xl:px-[20px] py-[20px]">
                                <button
                                    className="py-[10px] rounded w-full bg-[#5446D0] font-Inter font-medium text-sm text-[#FFFFFF] mx-auto outline-none"
                                    type='submit'
                                >
                                    Add
                                </button>
                            </div>

                            <button className="px-12 py-3 border-2 shadow-lg mt-2 hover:bg-[#D3D3D3] invisible outline-none">
                                Preview
                            </button>
                            <div className="px-12 py-3 border-2 shadow-lg mt-2 hover:bg-[#D3D3D3] invisible">
                                {audioData && (
                                    <audio id="audioPlayer" controls onLoadedData={() => {
                                        const audioLength = ref.current?.duration ?? 0;
                                        setFormatedAudioLength(formatTimeToMinSec(audioLength));
                                    }}>
                                        {/* <source src={`https://hooc.b-cdn.net/${audioData}`} type="audio/mpeg" /> */}
                                        <source src={audioData} type="audio/mpeg" />
                                    </audio>
                                )}
                            </div>

                        </div>
                        </form>

                    )

                    }
                </div>
            }
            {screen === "sidepanel" &&
                <SpeechSidePanel />
            }
        </>
    )
}

export default observer(SpeechPanel)