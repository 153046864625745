import Download from "../../assets/icons/Download.svg"
import Delete from "../../assets/icons/delete.svg"
import export_icon from "../../assets/icons/export.svg"
import { useStore } from "../../utility/hooks/UseStore"
import { localStorageNames } from "../../configs/constants"
import APICallLoader from '../../components/ui/common/APILoaderRing'
import deleteicon from '../../assets/delete_icon.png'
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react"
const { __USER_DATA } = localStorageNames

const ExcelListModal = ({ setExcelModel, setAddExcelModel, setGetExcelListFromStore, getExcelListFromStore }) => {

    const store = useStore()
    const [ConformExcelExportModal, setConformExcelExportModal] = useState(false)
    const [excelForExportModal, setExcelForExportModal] = useState('')
    const [apiCallLoader, setApiCallLoader] = useState(false)
    const [deleteModel, setDeleteModel] = useState(false)
    const { id } = useParams()

    //generate excel || export excel from here
    function handelExportExcel() {
        setApiCallLoader(true)
        const finaldata = {
            dataFileID: excelForExportModal._id,
            campaignID: id
        }
        store.videoStore.exportExcel({ finaldata, setGetExcelListFromStore, setApiCallLoader,  setExcelForExportModal, setConformExcelExportModal })
    }

    function deleteExcelModelHandler(item) {
        setDeleteModel(true)
        setDeleteItem(item)
    }

    const [deleteItem, setDeleteItem] = useState("")
    //delete excel call here
    function deleteExcelHandler(item) {
        setApiCallLoader(true)
        setDeleteModel(false)
        store.videoStore.DeleteExcel({ dataFileID : item._id, campaignID: id,  setGetExcelListFromStore, setApiCallLoader, setDeleteItem })
    }


    // this will fire when user try to add new excel becuase when user will try to upload than we have to verifiy headers of excel so first we have to update json data in database so we can see which of data is available in excel
    function saveBeforeAddExcel() {
        const jsonData = []
        const editor = store.videoStore.editorElements;
        editor.map((item) => {
            if (item.type === "text") {
                const textData = {}
                textData.id = item.id;
                textData.isVisible = item.isVisible;
                textData.type = item.type;
                textData.placement = item.placement;
                textData.timeFrame = item.timeFrame;
                textData.properties = item.properties;
                jsonData.push(textData);

            } else if (item.type === "button") {
                const buttonData = {}
                buttonData.id = item.id;
                buttonData.isVisible = item.isVisible;
                buttonData.type = item.type;
                buttonData.placement = item.placement;
                buttonData.timeFrame = item.timeFrame;
                buttonData.properties = item.properties;
                jsonData.push(buttonData);
            }
            else if (item.type === "video") {
                const videoData = {}
                videoData.id = item.id;
                videoData.isVisible = item.isVisible;
                videoData.type = item.type;
                videoData.placement = item.placement;
                videoData.timeFrame = item.timeFrame;
                videoData.properties = item.properties;
                jsonData.push(videoData);
            }
            else if (item.type === "audio") {
                const speechData = {}
                speechData.id = item.id;
                speechData.isVisible = item.isVisible;
                speechData.type = item.type;
                speechData.placement = item.placement;
                speechData.timeFrame = item.timeFrame;
                speechData.properties = item.properties;
                jsonData.push(speechData);
            }
            else if (item.type === 'image') {
                const imageData = {}
                imageData.id = item.id;
                imageData.isVisible = item.isVisible;
                imageData.type = item.type;
                imageData.placement = item.placement;
                imageData.timeFrame = item.timeFrame;
                imageData.properties = item.properties;
                jsonData.push(imageData)
            }
        })
        const data = {
            jsonData: jsonData,
            animationData: JSON.stringify(store.videoStore.animations),
        }
        setApiCallLoader(true)
        store.videoStore.JsonUpdate({ campaignId : id , data, setApiCallLoader })
    }


    return (<>
        {deleteModel &&
            <div className="fixed top-0 left-0 right-0 bottom-0 w-full bg-black bg-opacity-50 z-50">
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#272836] p-[24px] rounded-md font-Inter text-[#FFFFFF] w-[400px]">
                    <img src={deleteicon} alt="deleteicon" className=" mx-auto w-[100px] h-auto object-contain"></img>
                    <p className="text-[#ABABC0] my-[20px]  font-Inter font-normal text-[14px] leading-[16.94px] text-center tracking-[0.48px]">Are you sure you want to delete excel?</p>
                    <div className="flex justify-center items-center text-sm gap-[12px]">
                        <button onClick={() => setDeleteModel(false)} className="border border-[#5446D0] rounded-md px-[20px] py-[9px] text-center font-medium">Cancel</button>
                        <button onClick={() => { deleteExcelHandler(deleteItem) }} className=" bg-[#5446D0] rounded-md px-[20px] py-[10px] text-center font-semibold">Delete</button>
                    </div>
                </div>
            </div>
        }
        {ConformExcelExportModal &&
            <div className="fixed top-0 z-[50] left-0 right-0 bottom-0 w-full bg-black bg-opacity-50">
                <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#272836] p-[24px] rounded-md font-Inter text-[#FFFFFF] w-[400px]">
                    <h1 className="font-medium text-lg">Export Excel</h1>
                    <p className="text-sm font-medium text-[#ABABC0] py-[15px]">{`Are you sure you want to export excel?`}</p>
                    <div className="flex justify-end items-center text-sm gap-[12px]">
                        <button onClick={() => setConformExcelExportModal(false)} className=" border border-[#5446D0] rounded-md px-[20px] py-[9px] text-center font-medium">Cancel</button>
                        <button onClick={() => { handelExportExcel() }} className=" bg-[#5446D0] rounded-md px-[20px] py-[10px] text-center font-semibold">Export</button>
                    </div>
                </div>
            </div>
        }

        {/* API Loader */}
        <div className={`${apiCallLoader ? 'flex' : 'hidden'} fixed top-0 left-0 overflow-hidden justify-center items-center z-[9999]`}>
            <APICallLoader />
        </div>

        {/* Excel List Modal */}
        <div div className={` bg-black fixed top-0 left-0 right-0 w-full h-full z-40 bg-opacity-80 flex justify-center items-center`}>
            <div className='w-[100%] h-[100%] relative flex justify-center items-center'>
                <div onClick={() => { setExcelModel(false) }} className='w-[100%] h-[100%] absolute opacity-[0.7] top-0 left-0 z-[1]'></div>
                <div className='w-[1030px] p-[20px] m-[10px] bg-[#1E1E29] flex flex-col items-center z-[1] rounded-[8px]'>
                    <div className='flex justify-between items-center w-[100%]'>
                        <p className='text-[#FFFFFF] font-Inter font-semibold text-[18px] leading-[21.78px]'>All Excel</p>
                        <button className="bg-[#5446D0] font-Inter font-medium text-[14px] 2xl:text-[16px] py-[10px] 2xl:py-[8px] px-[16px] 2xl:px-[32px] rounded-[4px] text-[#FFFFFF] cursor-pointer outline-none" onClick={() => { saveBeforeAddExcel(); setAddExcelModel(true) }}>
                            Add Excel
                        </button>
                    </div>
                    <div className='w-[100%] max-h-[410px] overflow-y-auto my-[20px] scroll-smooth scrollbar-thin scrollbar-thumb-[#5446D0] scrollbar-track-transparent border-[1px] border-[#3B3B4F]'>
                        <table className='w-[100%]'>
                            <thead className='sticky top-0 bg-[#3B3B4F] z-[1]'>
                                <tr className='border-collapse'>
                                    <th className='text-white w-[340px] text-left py-[20px] pl-[20px]'>Name</th>
                                    <th className='text-white w-[85px] text-center py-[20px]'>Status</th>
                                    {/* <th className='text-white w-[189.35px] text-center py-[20px]'>Expired</th> */}
                                    <th className='text-white w-[275.65px] text-end py-[20px] pr-[20px]'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {store.videoStore.ExcelListForSingleCampaign && store.videoStore.ExcelListForSingleCampaign.map((item, i) =>
                                    <tr key={i} className='border-collapse border-y-[1px] border-[#3B3B4F]'>
                                        <th className='text-left py-[20px] pl-[20px] font-Inter font-medium text-[14px] leading-[16.94px] text-[#ABABC0] flex items-center justify-between'>
                                            {item.name ? item.name : item?.excelId?.length > 25 ? item.excelId.substring(0, 30) + '...' : item.excelId}
                                            <div onClick={() => {store.videoStore.downloadExcel({ dataFileID : item._id , setApiCallLoader})
                                                 setApiCallLoader(true)
                                            }} className='flex items-center cursor-pointer py-[6px] px-[8px] rounded-[4px] border mx-[20px] border-[#3B3B4F]'><img src={Download} alt='delete' className='' /></div>
                                        </th>
                                        <th className='py-[20px]'>
                                            <span className={`p-[7px] rounded-[5px] ${item.isGenerated === true ? "text-[#2FB273] bg-[#125656CC]" : "text-[#EC405F] bg-[#671F45CC]"} text-[13px] font-Inter`}>
                                                {item.isGenerated === true ? "Active" : "Inactive"}
                                            </span>
                                        </th>
                                        {/* <th className='text-center py-[20px] font-Inter font-medium text-[14px] leading-[16.94px] text-[#ABABC0]'>
                                            {item.isGenerated === true ? new Date(item.expiresAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : "-"}
                                        </th> */}
                                        <th className='text-white  py-[20px] pr-[20px] flex justify-end'>
                                            {item.isGenerated === true ?
                                                <a href={item.exportedExcel} onClick={() => {
                                                    if(!item.exportedExcel){
                                                        const finaldata = {
                                                            dataFileID: item._id,
                                                            campaignID: id
                                                        }
                                                        store.videoStore.exportExcel({ finaldata, setGetExcelListFromStore, setApiCallLoader,  setExcelForExportModal, setConformExcelExportModal })
                                                    }
                                                }} download={'exported'} className=' cursor-pointer flex items-center py-[6px] px-[8px] rounded-[4px] bg-[#5446D0] text-[#EEEEEE] font-Inter font-medium text-[14px] leading-[16.94px] whitespace-nowrap'><img src={Download} alt='delete' className='mr-[6px]' />Exported Excel</a>
                                                :
                                                // handelExportExcel(item)
                                                <button className='flex items-center py-[6px] px-[16px] rounded-[4px] bg-[#5446D0] text-[#EEEEEE] font-Inter font-medium text-[14px] leading-[16.94px] whitespace-nowrap cursor-pointer' onClick={() => { setConformExcelExportModal(true); 
                                                    setExcelForExportModal(item)
                                                    saveBeforeAddExcel()
                                                 }}><img src={export_icon} alt='delete' className='mr-[6px] w-[20px] h-[18px]' />Export Excel</button>
                                            }

                                            <img src={Delete} alt='delete' className={`w-[16.25px] ml-[10px] ${store.videoStore.ExcelListForSingleCampaign?.length === 1 ? "opacity-[0.2] cursor-no-drop" : "opacity-1 cursor-pointer"}`} onClick={() => { store.videoStore.ExcelListForSingleCampaign?.length !== 1 && deleteExcelModelHandler(item) }} />
                                        </th>
                                    </tr>
                                )}
                                {/* <tr className='border-collapse border-y-[1px] border-[#3B3B4F]'>
                                    <th className='text-left py-[20px] pl-[20px] font-Inter font-medium text-[14px] leading-[16.94px] text-[#ABABC0]'>
                                        Lorem Ipsum is simply dummy text of industry.
                                    </th>
                                    <th className='text-white  py-[20px]'>
                                        <p className='py-[6px] px-[16px] rounded-[20px] text-[#2FB273] font-Inter font-semibold  bg-[#125656CC]'>
                                            Inactive
                                        </p>
                                    </th>
                                    <th className='text-center py-[20px] font-Inter font-medium text-[14px] leading-[16.94px] text-[#ABABC0]'>
                                        August 3, 2024
                                    </th>
                                    <th className='text-white  py-[20px] pr-[20px] flex justify-end'>
                                        <button className='flex items-center py-[6px] px-[8px] rounded-[4px] bg-[#5446D0] text-[#EEEEEE] font-Inter font-medium text-[14px] leading-[16.94px] whitespace-nowrap'><img src={Download} alt='delete' className='mr-[6px]' />Exported Excel</button>
                                        <button className='flex items-center py-[6px] px-[8px] rounded-[4px] border text-[#EEEEEE] font-Inter font-medium text-[14px] leading-[16.94px] mx-[20px] border-[#3B3B4F]'><img src={Download} alt='delete' className='mr-[6px]' />Excel</button>
                                        <img src={Delete} alt='delete' className='w-[16.25px] cursor-pointer' />
                                    </th>
                                </tr>

                                <tr className='border-collapse border-y-[1px] border-[#3B3B4F]'>
                                    <th className='text-left py-[20px] pl-[20px] font-Inter font-medium text-[14px] leading-[16.94px] text-[#ABABC0]'>
                                        Lorem Ipsum is simply dummy text of industry.
                                    </th>
                                    <th className='text-white  py-[20px]'>
                                        <p className='py-[6px] px-[16px] rounded-[20px] text-[#EC405F] font-Inter font-semibold  bg-[#671F45CC]'>
                                            Inactive
                                        </p>
                                    </th>
                                    <th className='text-center py-[20px] font-Inter font-medium text-[14px] leading-[16.94px] text-[#ABABC0]'>
                                        August 3, 2024
                                    </th>
                                    <th className='text-white  py-[20px] pr-[20px] flex justify-end'>
                                        <button className='flex items-center py-[6px] px-[8px] rounded-[4px] bg-[#5446D0] text-[#EEEEEE] font-Inter font-medium text-[14px] leading-[16.94px] whitespace-nowrap'><img src={Download} alt='delete' className='mr-[6px]' />Exported Excel</button>
                                        <button className='flex items-center py-[6px] px-[8px] rounded-[4px] border text-[#EEEEEE] font-Inter font-medium text-[14px] leading-[16.94px] mx-[20px] border-[#3B3B4F]'><img src={Download} alt='delete' className='mr-[6px]' />Excel</button>
                                        <img src={Delete} alt='delete' className='w-[16.25px] cursor-pointer' />
                                    </th>
                                </tr>

                                <tr className='border-collapse border-y-[1px] border-[#3B3B4F]'>
                                    <th className='text-left py-[20px] pl-[20px] font-Inter font-medium text-[14px] leading-[16.94px] text-[#ABABC0]'>
                                        Lorem Ipsum is simply dummy text of industry.
                                    </th>
                                    <th className='text-white  py-[20px]'>
                                        <p className='py-[6px] px-[16px] rounded-[20px] text-[#2FB273] font-Inter font-semibold  bg-[#125656CC]'>
                                            Inactive
                                        </p>
                                    </th>
                                    <th className='text-center py-[20px] font-Inter font-medium text-[14px] leading-[16.94px] text-[#ABABC0]'>
                                        August 3, 2024
                                    </th>
                                    <th className='text-white  py-[20px] pr-[20px] flex justify-end'>
                                        <button className='flex items-center py-[6px] px-[8px] rounded-[4px] bg-[#5446D0] text-[#EEEEEE] font-Inter font-medium text-[14px] leading-[16.94px] whitespace-nowrap'><img src={Download} alt='delete' className='mr-[6px]' />Exported Excel</button>
                                        <button className='flex items-center py-[6px] px-[8px] rounded-[4px] border text-[#EEEEEE] font-Inter font-medium text-[14px] leading-[16.94px] mx-[20px] border-[#3B3B4F]'><img src={Download} alt='delete' className='mr-[6px]' />Excel</button>
                                        <img src={Delete} alt='delete' className='w-[16.25px] cursor-pointer' />
                                    </th>
                                </tr>

                                <tr className='border-collapse border-y-[1px] border-[#3B3B4F]'>
                                    <th className='text-left py-[20px] pl-[20px] font-Inter font-medium text-[14px] leading-[16.94px] text-[#ABABC0]'>
                                        Lorem Ipsum is simply dummy text of industry.
                                    </th>
                                    <th className='text-white  py-[20px]'>
                                        <p className='py-[6px] px-[16px] rounded-[20px] text-[#EC405F] font-Inter font-semibold  bg-[#671F45CC]'>
                                            Inactive
                                        </p>
                                    </th>
                                    <th className='text-center py-[20px] font-Inter font-medium text-[14px] leading-[16.94px] text-[#ABABC0]'>
                                        August 3, 2024
                                    </th>
                                    <th className='text-white  py-[20px] pr-[20px] flex justify-end'>
                                        <button className='flex items-center py-[6px] px-[8px] rounded-[4px] bg-[#5446D0] text-[#EEEEEE] font-Inter font-medium text-[14px] leading-[16.94px] whitespace-nowrap'><img src={Download} alt='delete' className='mr-[6px]' />Exported Excel</button>
                                        <button className='flex items-center py-[6px] px-[8px] rounded-[4px] border text-[#EEEEEE] font-Inter font-medium text-[14px] leading-[16.94px] mx-[20px] border-[#3B3B4F]'><img src={Download} alt='delete' className='mr-[6px]' />Excel</button>
                                        <img src={Delete} alt='delete' className='w-[16.25px] cursor-pointer' />
                                    </th>
                                </tr>

                                <tr className='border-collapse border-y-[1px] border-[#3B3B4F]'>
                                    <th className='text-left py-[20px] pl-[20px] font-Inter font-medium text-[14px] leading-[16.94px] text-[#ABABC0]'>
                                        Lorem Ipsum is simply dummy text of industry.
                                    </th>
                                    <th className='text-white  py-[20px]'>
                                        <p className='py-[6px] px-[16px] rounded-[20px] text-[#2FB273] font-Inter font-semibold  bg-[#125656CC]'>
                                            Inactive
                                        </p>
                                    </th>
                                    <th className='text-center py-[20px] font-Inter font-medium text-[14px] leading-[16.94px] text-[#ABABC0]'>
                                        August 3, 2024
                                    </th>
                                    <th className='text-white  py-[20px] pr-[20px] flex justify-end'>
                                        <button className='flex items-center py-[6px] px-[8px] rounded-[4px] bg-[#5446D0] text-[#EEEEEE] font-Inter font-medium text-[14px] leading-[16.94px] whitespace-nowrap'><img src={Download} alt='delete' className='mr-[6px]' />Exported Excel</button>
                                        <button className='flex items-center py-[6px] px-[8px] rounded-[4px] border text-[#EEEEEE] font-Inter font-medium text-[14px] leading-[16.94px] mx-[20px] border-[#3B3B4F]'><img src={Download} alt='delete' className='mr-[6px]' />Excel</button>
                                        <img src={Delete} alt='delete' className='w-[16.25px] cursor-pointer' />
                                    </th>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                    <button className='rounded-[4px] border border-[#3B3B4F] px-[32px] py-[11px] text-[#ABABC0] font-Inter font-medium text-[14px] leading-[16px]' onClick={() => { setExcelModel(false) }}>Close</button>
                </div>
            </div>
        </div >
    </>)
}

export default observer(ExcelListModal)