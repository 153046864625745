import { makeObservable } from "mobx"
import { userStore } from "./UserStore"
import { VideoStore } from "./VideoStore"
import { PreviewStore } from "./PreviewStore"

export class Store {
    userRegister
    videoStore
    previewStore
    constructor() {
        this.userRegister = new userStore()
        this.videoStore = new VideoStore()
        this.previewStore = new PreviewStore()
    }
}