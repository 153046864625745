export const colorToHex = (color) => {
    // Create a temporary element and set its color
    let tempElement = document.createElement("div");
    tempElement.style.color = color;

    // Append the element to the document to compute the style
    document.body.appendChild(tempElement);

    // Get the computed color (in RGB format)
    let rgb = window.getComputedStyle(tempElement).color;

    // Remove the temporary element
    document.body.removeChild(tempElement);

    // Extract the RGB values using a regular expression
    let rgbValues = rgb.match(/\d+/g);

    // Convert the RGB values to hexadecimal
    let hexColor = `#${((1 << 24) + (parseInt(rgbValues[0]) << 16) + (parseInt(rgbValues[1]) << 8) + parseInt(rgbValues[2])).toString(16).slice(1).toUpperCase()}`;

    return hexColor;
}
