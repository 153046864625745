import React from 'react'
import { localStorageNames } from '../../configs/constants';
import not_found from '../../assets/notfound.png'
import hyperclip_img from '../../assets/login_logo.png'
import { Link } from 'react-router-dom';
function PageNotFound() {
const { __USER_DATA , } = localStorageNames;

    return (
        <div className='w-full min-h-[100vh] bg-[#1e1e29] max-lg:py-[20px] max-md:px-[10px]  max-xl:px-[14px]  lg:flex items-center justify-center gap-36 font-inter'>
            <div className='px-[20px] py-[20px] font-inter'>
                <img src={hyperclip_img} alt='hyperclip_img' className='max-lg:mx-auto' />
                <div className='max-lg:text-center'>
                    <h1 className='text-[80px] font-bold bg-gradient-to-l from-[#c297f2] from-10%  to-[#7939f3] to-100% bg-clip-text text-transparent'>404</h1>
                    <p className='text-[36px] font-semibold text-white'>Page not found</p>
                    <p className='text-[20px] pt-[12px] pb-[45px] text-white'>The page you're looking for doesn't exist.</p>
                    <Link to={localStorage?.getItem(__USER_DATA) ? '/videos?limit=9&page=1' : '/login'} 
                    className='px-[15px] py-[12px] bg-[#5446D0] font-semibold rounded-[10px] text-white'>Back To Home</Link>
                </div>
            </div>
            <div className='max-lg:mt-[50px]'>
                <img src={not_found} alt='not_found' className='max-lg:mx-auto max-h-[600px]' />
            </div>
        </div>

    )
}

export default PageNotFound