import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import reset_icon from '../../../../assets/icons/reset.svg'
import minus_icon from '../../../../assets/icons/minus.svg'
import plus_icon from '../../../../assets/icons/plus.svg'
import replace_icon from '../../../../assets/icons/replace.svg'
import { useStore } from '../../../../utility/hooks/UseStore'
import upload_icon from '../../../../assets/icons/upload.svg'
import toast from 'react-hot-toast'
import { localStorageNames } from "../../../../configs/constants"
import APICallLoader from '../../../../components/ui/common/APILoaderRing'
import { useParams } from 'react-router-dom'
import { decryptData } from '../../../../utility/utils'
const { __USER_DATA , __PLAN_DATA} = localStorageNames

const VideoResourcesPanel = () => {

    const mainStore = useStore();
    const store = mainStore.videoStore;
    const selectedElement = store.selectedElement;
    const editorElement = selectedElement?.id;


    // useEffect(() => {
    //     if (editorElement) {
    //         const element = store.editorElements.find((element) => element.type === 'video');
    //         store.setSelectedElement(element);
    //     }
    // },[editorElement])

    useEffect(() => {
        store?.singleCampaignData?.jsonData?.length > 0 && store?.singleCampaignData?.jsonData?.map(item => {
           if(item?.type === 'video'){
               setVolume(store.volumeOfVideo)
           }
        })
    },[store?.singleCampaignData])


    const [screen, setscreen] = useState("video");
    const [apiCallLoader, setApiCallLoader] = useState(false);
    const [replaceModel, setReplaceModel] = useState(false);
    const [confirmModel, setConfirmModel] = useState(false);
    const [volume, setVolume] = useState(100);

    const isVideoFile = (file) => {
        const acceptedTypes = ["video/mp4", "video/m4v"];
        return acceptedTypes.includes(file.type);
    };

  const savedPlanData = decryptData((localStorage.getItem(__PLAN_DATA)));

    const { id } = useParams()

    const handelVideo = (e) => {
        const file = e.target.files?.[0];
        if (!file) return;
        const editor = store.editorElements;
        const animation = store.animations

        if (!isVideoFile(file)) {
            toast.error("Please upload a valid video file (MP4 or M4V).")
            return;
        } else {
            let videoDuration;
            const videoUrl = URL.createObjectURL(file);
            const videoElement = document.createElement('video');
            videoElement.src = videoUrl;
            videoElement.onloadedmetadata = () => {
                videoDuration = videoElement.duration * 1000; 


            
                setApiCallLoader(true)
                if (store.editorElements.length === 0) {
                    const formdata = new FormData()
                    formdata.append("video", file)
                    store.uploadVideo({ campaignID : id  , formdata, setApiCallLoader, volume, setReplaceModel })
                }  else if (((file.size / 1024) / 1024).toFixed() > 20 || videoElement?.duration > Number(savedPlanData?.maxVideoSec)) {
                    toast.error(`${`Not Allow Video Size More than 20MB And ${savedPlanData.maxVideoSec} Sec`}`);
                    setApiCallLoader(false)
                    return;
                }
                else {
                    const jsonData = []
    
                    editor.map((item) => {
                        if (item.type === "text") {
                            const textData = {}
                            textData.id = item.id;
                            textData.isVisible = item.isVisible;
                            textData.type = item.type;
                            textData.placement = item.placement;
                            // textData.timeFrame = item.timeFrame;
                            textData.timeFrame = {
                                ...item.timeFrame,
                                start : 0,
                                end : videoDuration
                            }
                            textData.properties = item.properties;
    
    
                            jsonData.push(textData);
    
                        } else if (item.type === "button") {
                            const buttonData = {}
                            buttonData.id = item.id;
                            buttonData.isVisible = item.isVisible;
                            buttonData.type = item.type;
                            buttonData.placement = item.placement;
                            // buttonData.timeFrame = item.timeFrame;
                            buttonData.timeFrame = {
                                ...item.timeFrame,
                                start : 0,
                                end : videoDuration
                            }
                            buttonData.properties = item.properties;
                            jsonData.push(buttonData);
                        }
                        else if (item.type === "video") {
                            const videoData = {}
                            videoData.id = item.id;
                            videoData.isVisible = item.isVisible;
                            videoData.type = item.type;
                            videoData.placement = item.placement;
                            // videoData.timeFrame = item.timeFrame;
                            videoData.timeFrame = {
                                ...item.timeFrame,
                                start : 0,
                                end : videoDuration
                            }
                            videoData.properties = item.properties;
                            // videoData.properties.isItFromReplace = true;
                            jsonData.push(videoData);
                        }

                    })
                    store.setEditorElements([...jsonData])
                    // const data = {
                    //     jsonData: jsonData,
                    //     animationData: JSON.stringify(store.animations),
                    //     campaignId: id
                    // }
    
                    const formdata = new FormData()
        
                    formdata.append("video", file)
                    formdata.append("jsonData", JSON.stringify(jsonData))
                    formdata.append("animationData", JSON.stringify(store.animations))
                    formdata.append("xyz", 'sdfdsf')

                    store.uploadVideo({ campaignID : id , formdata, setApiCallLoader, volume, setReplaceModel, })
                }

                URL.revokeObjectURL(videoUrl); 
            };

        }
    };

    const handleVolumeChange = (e) => {
        const newVolume = parseInt(e.target.value);
        // setVolume(parseInt(e.target.value));
        setVolume(newVolume);

        // if (videoRef.current) {
        //   videoRef.current.volume = parseInt(e.target.value) / 100;
        // }
        // const rangeInput = document.querySelector("input[type='range']")
        // const progress = (newVolume / rangeInput.max) * 100;

        // rangeInput.stylehttp://localhost:3000/.background = `linear-gradient(to right, #5446D0 0%, #5446D0 ${progress}%, #ABABC0 ${progress}%, #ABABC0 100%)`;
        // rangeInput.style.height = '4px';
        // rangeInput.style.marginTop = '8px';
        if (editorElement) {
            store.updateVolumn(editorElement, newVolume);
        }
    };

    const handelReset = () => {
        const newVolume = 100;
        setVolume(100)
        if (editorElement) {
            store.updateVolumn(editorElement, newVolume)
        }
    }

    const handleMinusClick = () => {
        if (volume > 0) {
            setVolume((prevVolume) => prevVolume - 1);
            if (editorElement) {
                store.updateVolumn(editorElement, volume)
            }
            // if (videoRef.current) {
            //   videoRef.current.volume = (volume - 1) / 100;
            // }
        }
    };

    const handlePlusClick = () => {
        if (volume < 100) {
            const newVolume = volume + 1;
            setVolume(newVolume);
            if (editorElement) {
                store.updateVolumn(editorElement, newVolume);
            }
            // if (videoRef.current) {
            //   videoRef.current.volume = newVolume / 100; 
            // }
        }
    };

    const handleModalClose = () => {
        if (confirmModel) {
            setConfirmModel(false);
        }
        if (replaceModel) {
            setReplaceModel(false);
        }
    };

    const handelRemove = () => {
        if (store.videoConfigs.length > 0) {
            // store.editorElements.map((element) => {
            //     if (element.type === "video") {
            //         store.removeElement(element)
            //     }
            // });
        }
        setConfirmModel(false);
        setReplaceModel(true);
        // store.setCanvas;
    };

    const [isDrag, setIsDrag] = useState(false)

    return (

        <>
            <div className={`${apiCallLoader ? 'flex' : 'hidden'} fixed top-0 left-0 overflow-hidden justify-center items-center z-[9999]`}>
                <APICallLoader />
            </div>
            {/* Confirm Model */}
            {confirmModel && (
                <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-20 bg-opacity-50">
                    <div className=" bg-[#272836] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-[20px]">
                        <div className="flex items-center justify-between">
                            <h1 className="font-Inter font-semibold text-lg text-[#FFFFFF]">Video</h1>
                        </div>
                        <div className="font-Inter font-medium text-sm pt-[16px]">
                            <p className=" text-[#ABABC0]">Are you sure you want to replace the video from the project?</p>
                            <p className=" text-[#ABABC0] opacity-70 mt-2">This will take a couple of seconds.</p>
                            <div className="flex justify-end items-center gap-[16px] pt-[20px]">
                                <button onClick={() => { handleModalClose() }} className="border border-[#5446D0] px-[24px] py-[9px] rounded text-center text-[#FFFFFF] outline-none"> Cancel</button>
                                <button onClick={() => { handelRemove() }} className="bg-[#5446D0] px-[24px] py-[10px] rounded text-center text-[#FFFFFF] outline-none"> Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* Replac Model */}
            {replaceModel && (
                <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-20 bg-opacity-50">
                    <div className=" bg-[#272836] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-[20px]">
                        <div className="flex items-center justify-between">
                            <h1 className="font-Inter font-semibold text-lg text-[#FFFFFF]">Replace Video</h1>
                            <img src={plus_icon} alt='close' className='rotate-[45deg] cursor-pointer' onClick={() => { setReplaceModel(false) }} />
                        </div>
                        <div>
                            <div className="relative h-[160px] rounded cursor-pointer border-dashed border-[1px] border-[#ABABC0]  flex justify-center items-center bg-[#3B3B4F] mt-[20px]">
                                <div className="absolute">
                                    <div className="flex flex-col items-center">
                                        <img src={upload_icon} alt="Upload" />
                                        <h3 className="font-Inter font-medium text-sm text-[#FFFFFF] my-[10px]">Upload a file</h3>
                                        <span className="block font-Inter font-normal text-xs text-[#ABABC0]">Drag & drop or browse to choose a file here</span>
                                    </div>
                                </div>
                                <input onChange={(e) => { handelVideo(e) }} type="file" accept=".mp4,.m4v" className=" cursor-pointer h-full w-full opacity-0" name="" />
                            </div>

                        </div>
                        <p className="font-Inter font-medium text-xs text-center text-[#FB0045] mt-[12px]">The video must be 20 MB and under {savedPlanData?.maxVideoSec} seconds.</p>
                    </div>
                </div>
            )}
            {screen === "video" &&
                <div className="">
                    {/* <div className="p-[10px] 2xl:p-[20px] pb-[0px]">
                        <h1 className="font-Inter font-semibold text-lg text-[#FFFFFF]">Video</h1>
                    </div> */}
                    <div className="p-[10px] 2xl:p-[20px]">
                        <div className="flex justify-between items-center">
                            <h3 className="text-[#ABABC0] font-Inter font-medium text-[14px] pb-[16px]"> Volume</h3>
                            <button onClick={(e) => { handelReset(e) }} className="flex gap-[6px] items-center outline-none">
                                <img src={reset_icon} alt="Reset" />
                                <span className="font-Inter font-medium text-xs text-[#FFFFFF]"> Reset</span>
                            </button>
                        </div>
                        <div className="flex gap-[8px] 2xl:gap-[22px] items-center justify-between bg-[#3B3B4F] rounded p-[8px] 2xl:p-[12px]">
                            <button onClick={(e) => { handleMinusClick(e) }} className="outline-none" >
                                <img src={minus_icon} alt="minus" />
                            </button>
                            <input onMouseDown={(e) => { setIsDrag(true) }}
                                onMouseUp={(e) => { setIsDrag(false) }} onChange={(e) => { handleVolumeChange(e) }} type="range" step={1} max={100} className={`bg-[#ABABC0] ${isDrag ? "cursor-grabbing" : "cursor-grab"} w-full h-[4px] outline-none range-edge`} value={volume} />
                            <button onClick={(e) => { handlePlusClick(e) }} className="outline-none" >
                                <img src={plus_icon} alt="plus" />
                            </button>
                            <p className="font-Inter font-medium text-sm text-[#FFFFFF] min-w-[40px]">{volume}%</p>
                        </div>
                    </div>
                    <hr className="bg-[#3B3B4F] border-0  h-[1px] " />
                    <div className="p-[10px] 2xl:p-[20px] font-Inter text-[#ABABC0]">
                        <h3 className=" text-[#ABABC0] font-Inter font-medium text-[14px] pb-[16px]">Replace</h3>
                        <button onClick={() => setConfirmModel(true)} className="flex gap-[10px] items-center justify-center w-full rounded bg-[#5446d0] p-[10px] outline-none">
                            <img src={replace_icon} alt="Replace" />
                            <span className="text-[#FFFFFF] font-medium text-sm">Replace Video</span>
                        </button>
                    </div>
                    <hr className="bg-[#3B3B4F] border-0  h-[1px] " />
                </div>
            }
        </>
    )
}

export default observer(VideoResourcesPanel)