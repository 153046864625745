import React from "react";
import { observer } from "mobx-react";
import { isEditorImageElement, isEditorVideoElement } from "../../../../store/VideoStore";
import { useStore } from "../../../../utility/hooks/UseStore";
import { EffectResource } from "../../entity/EffectResource";

export const EffectsPanel = observer(() => {
    const mainStore = useStore()
    const store = mainStore.videoStore
    const selectedElement = store.selectedElement;
    return (
        <>
            <div className="px-[16px] pt-[16px] pb-[8px] text-white font-Inter font-semibold text-[18px]">
                Effects
            </div>
            {selectedElement &&
                (isEditorImageElement(selectedElement) ||
                    isEditorVideoElement(selectedElement)) ? (
                <EffectResource editorElement={selectedElement} />
            ) : null}
        </>
    );
});
