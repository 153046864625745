import React, { useEffect, useState } from 'react'
import new_password from "../../assets/new_password.svg"
import showpasswordtype from "../../assets/icons/open_eyes.svg"
import showpasswordtypetext from "../../assets/icons/close_eyes.svg"
import login_arrow from "../../assets/icons/login_arrow.png"
import { Link, useNavigate } from 'react-router-dom'
import exclamation_mark from "../../assets/icons/exclamation_mark_red.png"
import APICallLoader from '../../components/ui/common/APILoaderRing'
import { useStore } from '../../utility/hooks/UseStore'

const AddNewPassword = () => {

    const store = useStore()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(window.location.search)
    const token = searchParams.get('token')
    const [passwordType, setPasswordType] = useState({
        password: 'password',
        confirmPassword: 'password',
    });
    const [newPassword, setNewPassword] = useState({
        password: '',
        confirmPassword: '',
    })
    const [error, setError] = useState({})
    const [runValidator, setRunValidator] = useState(false)
    const [apiCallLoader, setApiCallLoader] = useState(false)

    const togglePassword = (type) => {
        setPasswordType((prevState) => ({
            ...prevState,
            [type]: prevState[type] === 'password' ? 'text' : 'password'
        }))
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPassword((prevData) => ({
            ...prevData,
            [name]: String(value).trimStart(),
        }));
    };
    const passwordRegex = /^(?=.*[A-Z])(?=.*[@$!%*#?&.,_-])[A-Za-z\d@$!%*#?&.,_-]{8,16}$/;

    const validateRegisterData = () => {
        let errors = {};
        let isErrorFound = false
        setRunValidator(true)
        // Password validation
        if (!newPassword.password.trim()) {
            errors.password = 'Password is required.';
            isErrorFound = true
        } else if (!passwordRegex.test(newPassword.password)) {
            errors.password = 'Password must be 8–16 characters, with at least one uppercase letter and one special character (e.g. A, !, @, #, $).';
            isErrorFound = true
        } else {
            delete errors.password;
        }

        // Confirm Password validation
        if (!newPassword.confirmPassword.trim()) {
            errors.confirmPassword = 'Confirm Password is required.';
            isErrorFound = true
        } else if (newPassword.confirmPassword !== newPassword.password) {
            errors.confirmPassword = 'Password and Confirm Password must be same.';
            isErrorFound = true
        } else {
            delete errors.confirmPassword;
        }

        setError(errors);
        return isErrorFound
    };


    const handelSubmit = (e) => {
        e.preventDefault()
        const isError = validateRegisterData()
        if (isError) return;
        setApiCallLoader(true)

        const finalData = {
            password: newPassword.password,
        }
        store.userRegister.setNewPassword({ token, finalData, navigate, loader: setApiCallLoader })
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (runValidator) {
                validateRegisterData()
            }
        }, 150)

        return () => clearTimeout(timer)
    }, [newPassword])

    return (<>
        <div className={`${apiCallLoader ? 'flex' : 'hidden'} fixed top-0 left-0 overflow-hidden justify-center items-center z-[30]`}>
            <APICallLoader />
        </div>
        <div className="w-full h-[100vh] bg-[#1C1C26] flex justify-center items-center">
            <div className="flex flex-col gap-[40px] items-center justify-center px-[20px]">
                <img src={new_password} alt="somethingwentwrong" />
                <form className="max-w-[370px]  font-Inter" onSubmit={(e) => { handelSubmit(e) }} >
                    <h3 className="font-semibold text-[#FFFFFF] text-2xl text-center">Set your new password</h3>
                    <p className="font-medium text-[#ABABC0] text-base pt-[12px] pb-[40px] text-center">Your new password should be different from password previously used.</p>
                    <div className='w-[100%] relative'>
                        <label htmlFor='password' className=" font-Inter text-[14px] font-semibold text-[#fff]">Password <span className='text-[#FF5364]'>*</span></label>
                        <div className='flex items-center gap-[10px]'>
                            <input className=" w-full rounded-[6px] border border-[#2C2D3C]  bg-transparent text-[#ABABC0] placeholder:text-[#ABABC0] py-[9px] mt-[8px] px-[10px] text-[14px] mb-[4px] outline-none" placeholder="Enter your password" type={passwordType.password} autoComplete="off" name='password' value={newPassword.password} onChange={(e) => handleInputChange(e)} maxLength={16}></input>
                            {error.password &&
                                <img src={exclamation_mark} alt='' className='absolute top-[44px] right-[10px]' />
                            }
                            {
                                passwordType.password === 'password' ?
                                    <img src={showpasswordtype} alt='*' className={`absolute top-[44px] ${error.password ? "right-[40px]" : "right-[10px]"}  cursor-pointer`} onClick={() => { togglePassword('password') }} />
                                    :
                                    <img src={showpasswordtypetext} alt='*' className={`absolute top-[44px] ${error.password ? "right-[40px]" : "right-[10px]"}  cursor-pointer`} onClick={() => { togglePassword('password') }} />
                            }
                        </div>
                        <p className='text-[12px] leading-[14.52px] font-medium text-[#FF5364] mb-[12px]'>{error.password ? error.password : ''}</p>
                    </div>


                    <div className='w-[100%] relative'>
                        <label htmlFor='confirmPassword' className=" font-Inter text-[14px] font-semibold text-[#fff]"> Confirm Password <span className='text-[#FF5364]'>*</span></label>
                        <div className='flex items-center gap-[10px]'>
                            <input className=" w-full rounded-[6px] border border-[#2C2D3C]  bg-transparent text-[#ABABC0] placeholder:text-[#ABABC0] py-[9px] mt-[8px] px-[10px] text-[14px] outline-none mb-[4px]" placeholder="Enter your confirm password" type={passwordType.confirmPassword} autoComplete="off" name='confirmPassword' value={newPassword.confirmPassword} onChange={(e) => handleInputChange(e)} maxLength={16} />
                            {error.confirmPassword &&
                                <img src={exclamation_mark} alt='*' className='absolute top-[44px] right-[10px]' />
                            }
                            {
                                passwordType.confirmPassword === 'password' ?
                                    <img src={showpasswordtype} alt='*' className={`absolute top-[44px] ${error.confirmPassword ? "right-[40px]" : "right-[10px]"}  cursor-pointer`} onClick={() => { togglePassword('confirmPassword') }} />
                                    :
                                    <img src={showpasswordtypetext} alt='*' className={`absolute top-[44px] ${error.confirmPassword ? "right-[40px]" : "right-[10px]"}  cursor-pointer`} onClick={() => { togglePassword('confirmPassword') }} />
                            }
                        </div>
                        <p className='text-[12px] leading-[14.52px] font-medium text-[#FF5364] mb-[12px]'>{error.confirmPassword ? error.confirmPassword : ''}</p>
                    </div>

                    <button
                        type="submit"
                        className=" font-Inter text-[14px] font-semibold bg-[#5446D0] my-[30px] py-[10px] rounded-[6px] text-[#fff] w-full outline-none"
                    >
                        Submit
                    </button>
                    <Link to={'/login'} className="cursor-pointer font-Inter max-w-[110px] mx-auto font-medium w-full justify-center text-[#ABABC0] text-[12px] flex items-center">
                        {" "}
                        <img alt="login_arrow" src={login_arrow} className=" mr-[10px]"></img>{" "}Back to Log in
                    </Link>
                </form>
            </div>
        </div>
    </>)
}

export default AddNewPassword;