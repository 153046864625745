'use client'
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../utility/hooks/UseStore";
import add_icon from "../../../../assets/icons/add.svg";
import input_false_icon from "../../../../assets/icons/false.svg";

export const defaultProps = {
    btnTxt: "Button",
    fontSize: 16,
    fontFamily: "Arial",
    fontWeight: 400,
    fill: "#FFFFFF",
    btnBg: "#000000",
    btnWidth: 100,
    btnHeight: 40,
    underline: false,
    fontStyle: 'normal',
    textAlign: "left",
    border: 0,
    borderColor: "#FFFFFF",
    borderRadius: 0,
    position: "left",
    txtOpacity: 1,
    btnOpacity: 1,
    lineHeight: 0,
    charSpacing: 0
};
export const ButtonLinkPanel = observer(() => {
    const [error, setError] = useState("")
    const [runValidator, setRunValidator] = useState(false)

    const mainStore = useStore()
    const store = mainStore.videoStore

    const [buttonLink, setButtonLink] = useState("");

    const handleVideoUrl = (e) => {
        e.preventDefault();

        const isError = validate()
        setRunValidator(true)
        if (isError) return;

        const buttonConfigs = {
            linkUrl : buttonLink,
            backgroundColor : "#000000",
            paddingX : 10,
            paddingY : 5,
            borderRadius : 0,
            originX : "center",
            originY : "center",
            stroke : "#FFFFFF",
            strokeWidth : 0.4,
            fontSize : 3,
            fontColor : "#FFFFFF",
            fontFamily : "roboto",
            underline : false,
            fontStyle : "normal",
            x : 0,
            y : 0,
            text : "Click Here!",
            fontWeight : 400,
            btnOpacity : 1,
            txtOpacity : 1,
            lineHeight : 1.16,
            letterSpacing : 0,
            textAlign: "left",
            created : new Date()?.getTime()
            
        }

        store.addButton(buttonConfigs)

        store.setSelectedMenuOption("Button Link")
    };
    // const websiteRegex = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;
    const websiteRegex = /^https:\/\//;

    const validate = () => {
        let errors = "";
        let isErrorFound = false

        // Email validation
        if (!buttonLink.trim()) {
            errors = 'Button Link is required.';
            isErrorFound = true
        } else if (!websiteRegex.test(buttonLink)) {
            errors = 'Button Link is invalid.';
            isErrorFound = true
        } else {
            setError("")
        }

        setError(errors);
        return isErrorFound
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (runValidator) {
                validate()
            }
        }, 150)

        return () => clearTimeout(timer)
    }, [buttonLink])


    return (
        <>
            <div className="p-[10px] 2xl:p-[20px]">
                {/* <div className="flex items-center">
                    <h1 className="font-Inter font-semibold text-lg text-[#FFFFFF]">
                        Button URL
                    </h1>
                </div> */}
                <form onSubmit={handleVideoUrl} className="">
                    <p className="text-[12px] 2xl:text-[14px] font-Inter font-normal text-[#ABABC0]">Link</p>
                    <div className="rounded-[4px] mt-[10px] py-[10px] 2xl:py-[12px] px-[12px] 2xl:px-[14px] flex col-span-4 bg-[#3B3B4F]">
                        <div className="focus:outline-none  flex items-center w-[22px] h-[8px] 2xl:w-[22px] 2xl:h-[8px] justify-center my-[auto]">
                            <svg width="26" height="11" className=" mx-auto transform rotate-[135deg]" viewBox="0 0 26 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5299 4.50476C10.5299 2.54804 12.1162 0.961914 14.0728 0.961914L21.4572 0.961914C23.4139 0.961914 25 2.54815 25 4.50476V6.49521C25 8.45193 23.4138 10.0381 21.4572 10.0381H20.1295" stroke="#ABABC0" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15.4697 6.49521C15.4697 8.45193 13.8835 10.0381 11.9269 10.0381L4.54249 10.0381C2.58578 10.0381 0.999647 8.45183 0.999647 6.49521V4.50476C0.999647 2.54804 2.58588 0.961914 4.54249 0.961914L5.87015 0.961914" stroke="#ABABC0" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <input
                            type="text"
                            value={buttonLink}
                            onChange={(e) => setButtonLink(e.target.value?.replace(/\s/g, ''))}
                            className="w-full text-[14px] mr-2 pl-[10px] focus:outline-none placeholder-[#ABABC0] rounded bg-transparent text-[#ABABC0]"
                            placeholder="Paste link here..."
                        />
                        {buttonLink?.length > 0 && <img onClick={() => setButtonLink("")} src={input_false_icon} className=" cursor-pointer" alt="test" />}
                    </div>
                    {error?.length > 0 && <p className='text-[12px] leading-[14.52px] font-medium text-[#FF5364] mb-[12px]'>{error}</p>}
                    <button type="submit" className="flex items-center justify-center gap-[10px] bg-[#5446D0] font-Inter font-medium text-sm text-[#FFFFFF] w-full p-[10px] rounded outline-none mt-[20px]" >
                        <img src={add_icon} alt="add" />
                        Add Button
                    </button>
                    <p className=" mt-4 text-[#ababc0] text-[13px]">Note : Link must be start with https://</p>
                </form>
            </div>
        </>
    );
});

ButtonLinkPanel.defaultProps = defaultProps;
