'use client'
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { action } from "mobx";

import ExcelJS from "exceljs";
import Excel from "exceljs"
// interface YourDataResourcesPanelProps {
//   setOpenModal: (open: boolean) => void;
//   setIntegrationModal: (open: boolean) => void;
// }

import APILoaderRing from "../../../ui/common/APILoaderRing"
import toast from "react-hot-toast";
import close_icon from "../../../../assets/icons/close.svg"
import googlesheet_icon from "../../../../assets/icons/google_sheet.svg"
import upload_icon from "../../../../assets/icons/upload.svg"


const YourDataResourcesPanel = observer(({ setOpenModal }) => {
    const [screen, setScreen] = useState("upload");
    const [headerSelectScreen, setHeaderSelectScreen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [headers, setHeaders] = useState([]);
    const [firstRow, setFirstRow] = useState([]);
    // const dispatch = useDispatch()
    const [selectedHeaders, setselectedHeaders] = useState([])
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [selectAll, setSelectAll] = useState(true);


    function selection(value) {
        const tempArrayForSelectHeader = []
        if (selectedHeaders.includes(value)) {
            selectedHeaders.map((item) => {
                if (item != value) {
                    tempArrayForSelectHeader.push(item)
                }
            })
            setselectedHeaders(tempArrayForSelectHeader)
        } else {
            setselectedHeaders([...selectedHeaders, value])
        }
    }

    const handleFileChange = (e) => {
        const files = e.target.files;

        if (files && files.length > 0) {
            const selected = files[0];
            const allowedExtensions = [".xlsx", ".xls"];
            const fileExtension = selected.name.split(".").pop();

            if (allowedExtensions.includes(`.${fileExtension}`)) {
                const wb = new Excel.Workbook();
                const reader = new FileReader();
                reader.readAsArrayBuffer(selected);
                reader.onload = () => {
                    const buffer = reader.result;
                    if (buffer instanceof ArrayBuffer) {
                        if (fileExtension === 'csv') {
                            // Handle CSV file
                            const textDecoder = new TextDecoder('utf-8');
                            const csvData = textDecoder.decode(buffer);
                            const rows = csvData.split('\n');
                            const rowCount = rows.length;
                            setTotalRowCount(rowCount);
                        } else {
                            // Handle Excel file
                            wb.xlsx.load(buffer).then((workbook) => {
                                workbook.eachSheet((sheet, id) => {
                                    let rowCount = 0;
                                    sheet.eachRow(() => {
                                        rowCount++;
                                    });
                                    setTotalRowCount(rowCount);
                                });
                            });
                        }
                    } else {
                        console.error('Failed to read file as array buffer.');
                    }
                };
                setSelectedFile(selected);
            } else {
                toast.error("Please upload a .xlsx or .xls file")
            }
        } else {
            console.error('No files selected.');
        }
    };

    const [userID, setUserID] = useState("")

    // const userData = JSON.parse(localStorage.getItem('userLogin'));
    useEffect(() => {
        const loginData = (localStorage.getItem('userLogin'));

        const userData = loginData ? JSON.parse(loginData) : ""

        if (userData) {
            setUserID(userData.userID)
        }

    }, [])


    const handleUploadButtonClick = () => {
        if (selectedHeaders.length === 0) {
            toast.error("Please select at least one header.")
        } else {
            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                for (let index = 0; index < selectedHeaders.length; index++) {
                    formData.append('selectedHeaders', selectedHeaders[index])
                }
                // formData.append('selectedHeaders', selectedHeaders);
                formData.append('adminID', userID)
                // dispatch(AddExcelFile(formData, setOpenModal, setHeaderSelectScreen, "popup"));
                // dispatch(FileLoader())
            } else {
                alert("Please select a file to upload");
            }
        }
    };

    const addYourData = action(() => {
        if (selectedFile) {
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
                if (event.target) {
                    const data = event.target.result;
                    let fileExtension = "";
                    if (selectedFile.name.includes(".")) {
                        fileExtension =
                            selectedFile.name.split(".").pop()?.toLowerCase() || "";
                    }

                    if (fileExtension === "csv") {
                        // Handle CSV file
                        let csvText = "";
                        if (typeof data === "string") {
                            csvText = data;
                        } else if (data instanceof ArrayBuffer) {
                            const decoder = new TextDecoder("utf-8");
                            csvText = decoder.decode(data);
                        }

                        const rows = csvText.split(/\r?\n/);
                        if (rows.length >= 2) {
                            const headerValues = rows[0].split(",");
                            setHeaders(headerValues);
                            setHeaderSelectScreen(true);
                            const firstRowValues = rows[1].split(",");
                            setFirstRow(firstRowValues);
                        } else {
                            alert("CSV file is empty or missing data rows.");
                        }
                    } else if (fileExtension === "xlsx" || fileExtension === "xls") {
                        const workbook = new ExcelJS.Workbook();
                        workbook.xlsx
                            .load(data)
                            .then(() => {
                                const worksheet = workbook.getWorksheet(1);
                                if (worksheet) {
                                    const headerValues = worksheet.getRow(1).values;
                                    setHeaders(headerValues);
                                    setHeaderSelectScreen(true);
                                    const firstRowValues = worksheet.getRow(2)
                                        .values;
                                    setFirstRow(firstRowValues);
                                } else {
                                    console.error("Worksheet not found.");
                                }
                            })
                            .catch((error) => {
                                console.error("Error loading Excel file:", error);
                                alert("Error loading Excel file. Please try again.");
                            });
                    } else {
                        alert("Unsupported file type");
                    }
                }
            };
            if (selectedFile.name.includes(".")) {
                fileReader.readAsArrayBuffer(selectedFile);
            } else {
                fileReader.readAsText(selectedFile);
            }
        }
    });

    useEffect(() => {
        if (selectedFile) {
            addYourData();
        }
    }, [selectedFile]);

    const handleModalClose = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        if (headers) {
            let temparray = []
            headers.map((header, index) => {
                if (header !== null) {
                    temparray.push(header)
                }
            })
            setselectedHeaders(temparray)
        }

    }, [headers])


    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            setselectedHeaders([...headers]);
        } else {
            setselectedHeaders([]);
        }
    };

    const loader = false

    return (
        <>

            {loader && (
                <div>
                    <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-40 bg-opacity-70">
                        <div className="z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <APILoaderRing />
                        </div>
                    </div>
                </div>
            )}
            <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-20 bg-opacity-50">

                {headerSelectScreen ? (

                    <div className="bg-[#272836] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-[20px] z-30">
                        <div className="flex items-center justify-between ">
                            <h1 className="font-Inter font-semibold text-lg text-[#FFFFFF]">Setup Columns</h1>
                            <button
                                className="outline-none"
                                onClick={() => setHeaderSelectScreen(false)}
                            >
                                <img src={close_icon} alt="close" />
                            </button>
                        </div>
                        <div className="flex justify-center font-Inter font-medium text-sm text-[#FFFFFF] pb-[30px]">
                            <p className="py-[10px] border-b border-[#5446D0] px-[34px] ">Upload CSV</p>
                            <p className="py-[10px] border-b border-[#5446D0] px-[24px]">Setup Columns</p>
                        </div>
                        <div className="bg-[#3B3B4F] border-b border-[#2C2D3C]  rounded-t flex justify-between py-[24px] px-[20px]">
                            <div className="flex items-center gap-[10px]">
                                <input
                                    id="myCheckbox"
                                    type="checkbox"
                                    onClick={toggleSelectAll}
                                    className="outline-none"
                                />
                                <p className="font-Inter font-medium text-sm text-[#FFFFFF]">Discard First Row</p>
                            </div>
                            <p className="font-Inter font-medium text-sm text-[#ABABC0]">total count: {totalRowCount}</p>
                        </div>
                        <div className="relative overflow-auto  scrollbar-thin scrollbar-track-[#3B3B4F] scrollbar-thumb-[#5A5A71] scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-corner-rounded-full w-[720px]">

                            <table className="w-full bg-[#3B3B4F] rounded-b mb-[10px]">
                                <thead className="text-sm font-Inter font-medium text-white uppercase">
                                    <tr>
                                        {headers.map((header, index) => (
                                            <th key={index} className="p-[20px]">
                                                <div className="flex justify-center items-center gap-[10px]">
                                                    <input
                                                        id="myCheckbox"
                                                        type="checkbox"
                                                        onClick={() => { selection(header) }}
                                                        className="outline-none"
                                                        checked={selectedHeaders.includes(header) ? true : false}

                                                    />
                                                    <p>{header}</p>
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="border-t border-[#2C2D3C] text-sm font-Inter font-medium text-[#ABABC0] text-center">
                                        {firstRow.map((cell, index) => (
                                            <td key={index} className="p-[20px]">
                                                {cell}
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="flex justify-center items-center gap-[20px] pt-[20px]">
                            <button
                                className="bg-[#3B3B4F] rounded py-[8px] w-[150px] outline-none font-Inter font-medium text-sm text-[#ABABC0]"
                                onClick={() => {
                                    setHeaderSelectScreen(false);
                                }}
                            >
                                Back
                            </button>
                            <button
                                className="bg-[#5446D0] rounded py-[8px] w-[150px] outline-none font-Inter font-medium text-sm text-[#FFFFFF]"
                                onClick={handleUploadButtonClick}>
                                Done
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className=" bg-[#272836] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30 rounded-[8px] p-[10px] 2xl:p-[20px] max-h-[450px] 2xl:max-h-none overflow-auto scrollbar-thin scrollbar-thumb-[#5446D0] scrollbar-track-transparent">
                        <div className="flex items-center justify-between">
                            <h1 className="font-Inter font-medium text-base 2xl:text-lg text-[#FFFFFF]">
                                Your Data
                            </h1>
                            <button className="outline-none" onClick={() => { setOpenModal(false) }}>
                                <img src={close_icon} alt="close" />
                            </button>
                        </div>

                        <div className="flex 2xl:mt-[23px]">
                            <div
                                onClick={() => setScreen("upload")}
                                className={`${screen === "upload"
                                    ? "border-[#5446D0]"
                                    : "border-[#3B3B4F]"
                                    } border-b-2  flex items-center justify-center gap-[10px] p-[10px] basis-1/2 cursor-pointer text-sm 2xl:text-base`}
                            >
                                <img src={upload_icon} alt="Upload" />
                                <span className="font-Inter text-[#ABABC0]">Upload CSV</span>
                            </div>
                            <div
                                onClick={() => setScreen("googlesheet")}
                                className={`${screen === "upload"
                                    ? "border-[#3B3B4F]"
                                    : "border-[#5446D0]"
                                    } border-b-2  flex items-center justify-center gap-[10px] p-[10px] basis-1/2 cursor-pointer text-sm 2xl:text-base`}
                            >
                                <img src={googlesheet_icon} alt="googlesheet" />
                                <span className="font-Inter text-[#ABABC0]">
                                    Add A Google Sheet
                                </span>
                            </div>
                        </div>
                        <div>
                            {screen === "upload" && (
                                <div className="pt-[16px] 2xl:pt-[26px] px-[20px] w-[760px]">
                                    <p className="font-Inter font-normal text-xl 2xl:text-[22px] text-[#FFFFFF] text-center">
                                        Enhance campaign performance through strategic data
                                        seeding
                                    </p>
                                    <p className="text-center text-[#ABABC0] pt-[10px] text-sm 2xl:text-base 2xl:pt-[20px] mx-[40px]">
                                        Empower your outreach campaigns with enriched,
                                        personalized data by seamlessly uploading your customer
                                        and prospect data
                                    </p>
                                    <div className="flex items-center justify-center px-[100px] mt-[20px] 2xl:mt-[33px]">
                                        <div className="basis-1/2 bg-[#3B3B4F] rounded border-dashed border-[1px] border-[#ABABC0] p-[15px] 2xl:p-[30px]">
                                            <div className="relative h-[90px]   flex justify-center items-center  2xl:mb-[20px]">
                                                <div className="absolute">
                                                    <div className="flex flex-col items-center">
                                                        <img src={upload_icon} alt="Upload" />
                                                        <h3 className="font-Inter font-medium text-sm text-[#FFFFFF] my-[10px]">
                                                            Upload a file
                                                        </h3>
                                                        <span className="block font-Inter font-normal text-xs text-[#ABABC0]">
                                                            Drag & drop or browse to choose a file here
                                                        </span>
                                                    </div>
                                                </div>
                                                <input
                                                    type="file"
                                                    accept=".xlsx, .xls"
                                                    onChange={handleFileChange}
                                                    className="h-full w-full opacity-0"
                                                    name=""
                                                />
                                            </div>
                                            <div className="flex items-center gap-[12px]">
                                                <div className="border-[#ABABC0] border-b w-32"></div>
                                                <p className="font-Inter font-normal text-xs text-[#ABABC0]">
                                                    Or
                                                </p>
                                                <div className="border-[#ABABC0] border-b w-32"></div>
                                            </div>
                                            <button
                                                // onClick={() => { setOpenModal(false); setIntegrationModal(true) }}
                                                className="bg-[#5446D0] rounded p-[10px] w-full font-Inter font-medium text-sm text-[#FFFFFF] mt-[10px] 2xl:mt-[20px] outline-none"
                                            >
                                                Integrations Library
                                            </button>
                                        </div>

                                        <div className="basis-1/2 font-Inter font-normal text-sm text-[#ABABC0] flex flex-col justify-center pl-[40px]">
                                            <p className="font-medium text-[#FFFFFF] pb-[24px] text-left">
                                                Instructions
                                            </p>
                                            <p className="pb-[12px]">1. Upload CSV</p>
                                            <p>2. Setup Columns</p>
                                        </div>
                                    </div>
                                    <div className="pt-[26px] px-[20px] max-w-[760px] mx-auto">
                                        <div className="relative overflow-x-auto  scrollbar-thin scrollbar-track-[#3B3B4F] scrollbar-thumb-[#5A5A71] rounded">
                                            <table className="w-full bg-[#3B3B4F]">
                                                <thead className="text-sm font-Inter font-medium text-white uppercase">
                                                    <tr>
                                                        {headers.map((header, index) => (
                                                            <th key={index} className="p-[20px]">
                                                                {header}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="border-t border-[#2C2D3C] text-sm font-Inter font-medium text-[#ABABC0] text-center">
                                                        {firstRow.map((cell, index) => (
                                                            <td key={index} className="p-[20px]">
                                                                {cell}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {screen === "googlesheet" && (
                                <div className="pt-[16px] px-[20px] w-[760px]">
                                    <div className="flex items-center justify-center px-[50px]  mt-[23px]">
                                        <div className="basis-[70%] font-Inter font-normal text-sm text-[#ABABC0] flex flex-col justify-center">
                                            <div className="bg-[#3B3B4F] border border-dashed border-[#ABABC0] rounded p-[30px] font-Inter text-[#FFFFFF] w-full">
                                                <p className="font-medium text-sm text-center">
                                                    Paste your spreadsheet URL here
                                                </p>
                                                <div className="rounded-[4px] mt-[20px] py-[10px] px-[14px] flex col-span-4 bg-[#272836]">
                                                    <div className="focus:outline-none  flex items-center">
                                                        <svg
                                                            width="26"
                                                            height="11"
                                                            className=" mx-auto transform rotate-[135deg]"
                                                            viewBox="0 0 26 11"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M10.5299 4.50476C10.5299 2.54804 12.1162 0.961914 14.0728 0.961914L21.4572 0.961914C23.4139 0.961914 25 2.54815 25 4.50476V6.49521C25 8.45193 23.4138 10.0381 21.4572 10.0381H20.1295"
                                                                stroke="#ABABC0"
                                                                strokeWidth="1.2"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M15.4697 6.49521C15.4697 8.45193 13.8835 10.0381 11.9269 10.0381L4.54249 10.0381C2.58578 10.0381 0.999647 8.45183 0.999647 6.49521V4.50476C0.999647 2.54804 2.58588 0.961914 4.54249 0.961914L5.87015 0.961914"
                                                                stroke="#ABABC0"
                                                                strokeWidth="1.2"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <input
                                                        type="url"
                                                        className="w-full text-[14px] pl-[10px] focus:outline-none placeholder-[#ABABC0] rounded bg-transparent text-[#ABABC0]"
                                                        required
                                                        placeholder="Paste link here..."
                                                    />
                                                </div>
                                                <p className="font-normal text-xs text-[#FB0045] pt-[10px] text-center">
                                                    The Sheet URL entered is not valid, copy the URL
                                                    direct from the browser.
                                                </p>
                                                <button className="w-full bg-[#5446D0] p-[10px] rounded font-medium text-sm text-[#FFFFFF] my-[20px] outline-none">
                                                    Submit
                                                </button>
                                                <div className="flex items-center justify-between gap-[12px] w-full">
                                                    <div className="border-[#ABABC0] border-b w-full"></div>
                                                    <p className="font-Inter font-normal text-xs text-[#ABABC0]">
                                                        Or
                                                    </p>
                                                    <div className="border-[#ABABC0] border-b w-full"></div>
                                                </div>
                                                <button className="w-full bg-[#5446D0] p-[10px] rounded font-medium text-sm text-[#FFFFFF] my-[20px] outline-none">
                                                    Select Account
                                                </button>
                                                <button className="w-full bg-[#5446D0] p-[10px] rounded font-medium text-sm text-[#FFFFFF] outline-none">
                                                    Integrations Library
                                                </button>
                                            </div>
                                        </div>

                                        <div className="basis-[30%] font-Inter font-normal text-sm text-[#ABABC0] flex flex-col justify-center pl-[40px]">
                                            <p className="font-medium text-[#FFFFFF] pb-[24px] text-left">
                                                Instructions
                                            </p>
                                            <p>1. Select Account</p>
                                            <p className="py-[12px]">2. Assign Sheet</p>
                                            <p>3. Setup Columns</p>
                                        </div>
                                    </div>
                                    <div className="pt-[23px]">
                                        <div className="relative overflow-x-auto  rounded">
                                            <table className="w-full  bg-[#3B3B4F]">
                                                <thead className="text-sm font-Inter font-medium text-white uppercase  ">
                                                    <tr>
                                                        <th scope="col" className="p-[20px]">
                                                            Name
                                                        </th>
                                                        <th scope="col" className="p-[20px]">
                                                            Contacts
                                                        </th>
                                                        <th scope="col" className="p-[20px]">
                                                            logos
                                                        </th>
                                                        <th scope="col" className="p-[20px]">
                                                            websites
                                                        </th>
                                                        <th scope="col" className="p-[20px]">
                                                            li profiles
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className=" border-t border-[#2C2D3C] text-sm font-Inter font-medium text-[#ABABC0] text-center">
                                                        <th scope="row" className="p-[20px]">
                                                            ABCD
                                                        </th>
                                                        <td className="p-[20px]">40</td>
                                                        <td className="p-[20px]">0</td>
                                                        <td className="p-[20px]">0</td>
                                                        <td className="p-[20px]">0</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
});


export default YourDataResourcesPanel;