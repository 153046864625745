import { useEffect, useRef, useState } from "react";
import plus_icon from "../../assets/icons/plus.svg";
import excel_upload from "../../assets/icons/excel_upload.svg";
import exclamation_mark from "../../assets/icons/exclamation_mark_red.png";
import { useParams } from "react-router-dom";
import { localStorageNames } from "../../configs/constants";
import { useStore } from "../../utility/hooks/UseStore";
import round_clear from "../../assets/round_close.png";
import { decryptData } from "../../utility/utils";
import toast from "react-hot-toast";
import ExcelJS from 'exceljs'
const { __USER_DATA } = localStorageNames;

const AddExcelModel = ({
  setAddExcelModel,
  setApiCallLoader,
  setGetExcelListFromStore,
}) => {
  const store = useStore();
  const [excel, setExcel] = useState({});
  const [excelName, setExcelName] = useState("");
  const [excelNameError, setExcelNameError] = useState("");
  const [error, setError] = useState("");
  const { id } = useParams();

  // upload excel function
  function UploadExcel() {
    const isError = validateRegisterData();

    if (isError) return;
    setApiCallLoader(true);
   
    let orderedSelection = headers?.filter(item => selectedHeaders.includes(item))
    var formdata = new FormData();
    formdata.append("dataFile", excel);
    formdata.append("fileName", excelName);
    // formdata.append("userId", userData._id);
    for (let index = 0; index < orderedSelection?.length; index++) {
      const element = orderedSelection[index];
      formdata.append("variable", typeof element === "string" ? element?.trim() : element);
    } 
    store.videoStore.uploadExcel({
      campaignID : id,
      formdata,
      setApiCallLoader,
      setAddExcelModel,
      setGetExcelListFromStore,
    });
  }

  // Validation Function for upload Excel
  function validateRegisterData() {
    let isErrorFound = false;

    if (excel.name === undefined) {
      setError("Excel is required.");
      isErrorFound = true;
    } else if (
      !(
        excel.name.endsWith(".xlsx") ||
        excel.name.endsWith(".xml") ||
        excel.name.endsWith(".xls")
      )
    ) {
      isErrorFound = true;
      setError("It only supports the .xls and .xlsx file formats.");
    } else {
      setError("");
    }

    if (!excelName) {
      setExcelNameError("Excel Name is required.");
      isErrorFound = true;
    } else {
      setExcelNameError("");
    }

    return isErrorFound;
  }

  useEffect(() => {
    if (error !== "" || excelNameError?.length > 0) {
      validateRegisterData();
    }
  }, [excel, excelName]);

  const excelinputRef = useRef();
  const oldHeaders = store.videoStore?.ExcelListForSingleCampaign[0]?.headers


// selcetc header modal section

const [mainScreen, setMainScreen] = useState("UploadFileScreen")
const [headers, setHeaders] = useState([]);
const [firstRow, setFirstRow] = useState([]);
const [selectedHeaders, setselectedHeaders] = useState([])

const planData = decryptData(localStorage.getItem('Amns_BR_jyw12'))

function selection(value) {
  const tempArrayForSelectHeader = []
  if (selectedHeaders.includes(value)) {
    selectedHeaders.map((item) => {
      if (item != value) {
        tempArrayForSelectHeader.push(item)
      }
    })
    if (selectedHeaders?.length > 1) {
      setselectedHeaders(tempArrayForSelectHeader)
    }
  } else if (selectedHeaders?.length < planData?.maxHeaders) {
    setselectedHeaders([...selectedHeaders, value])
  }else if(selectedHeaders?.length === planData?.maxHeaders){
    toast.error(`Only ${planData?.maxHeaders} headers allowed.`)
  }
}

const addYourData = () => {
  if (excel) {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      if (event.target) {
        const data = event.target.result;
        let fileExtension = "";
        if (excel.name.includes(".")) {
          fileExtension =
          excel.name.split(".").pop()?.toLowerCase() || "";
        }

        if (fileExtension === "csv") {
          // Handle CSV file
          let csvText = "";
          if (typeof data === "string") {
            csvText = data;
          } else if (data instanceof ArrayBuffer) {
            const decoder = new TextDecoder("utf-8");
            csvText = decoder.decode(data);
          }

          const rows = csvText.split(/\r?\n/).filter(row => row.trim() !== "");
          if (rows.length >= 2) {
            const headerValues = rows[0].trim().split(",").map(header => header.trim());
            setHeaders(headerValues);
            // setHeaderSelectScreen(true);
            setMainScreen("headerSelectScreen")
            const firstRowValues = rows[1].trim().split(",").map(value => value.trim());
            setFirstRow(firstRowValues);
          } else {
            console.error("CSV file is empty or missing data rows.");
            toast.error("CSV file is empty or missing data rows.");
          }
        } else if (fileExtension === "xlsx" || fileExtension === "xls") {
          const workbook = new ExcelJS.Workbook();
          workbook.xlsx
            .load(data)
            .then(() => {
              const worksheet = workbook.getWorksheet(1);
              if (worksheet && worksheet?.actualRowCount > 0) {

                const headerValues = worksheet.getRow(1).values?.filter(val => val !== null);;
               
                setHeaders(headerValues);
                // setHeaderSelectScreen(true);

                const firstRowValues = worksheet.getRow(2).values?.filter(val => val !== null).map(val => {
                  if (typeof val === 'object') {
                      // Extract the first key's value, or specify a key (e.g., val['yourKey'])
                      const firstKey = Object.keys(val)[0];
                      return val[firstKey]; // Return the value of the first key
                  }
                  return val; // If not an object, return the primitive value
              });
                setFirstRow(firstRowValues);
                // const selectedValues = headerValues.slice(0, 1); // Get the first two headers
                // setselectedHeaders(selectedValues);
                const headerSet = new Set();
                const duplicates = headerValues.filter((header) => {
                  if (headerSet.has(header)) {
                    return true;  // Found a duplicate
                  } else {
                    headerSet.add(header);
                    return false;  // Not a duplicate
                  }
                });

                if (headerValues?.length !== firstRowValues.length) {
                  toast.error(`Headers and data columns count mismatch. Please correct the file.` , {duration: 2000});
                  if (excelinputRef.current) {
                    excelinputRef.current.value = ''; // Reset the input using the ref
                  }
                  setExcel({});
                  setMainScreen("UploadFileScreen")
                  return; 
                } else {
                  setMainScreen("headerSelectScreen")
                }
                if (duplicates?.length > 0) {
                  toast.error(`Duplicate headers found: ${duplicates.join(", ")}. Please correct the file.`);
                  setMainScreen("UploadFileScreen")
                  if (excelinputRef.current) {
                    excelinputRef.current.value = ''; // Reset the input using the ref
                  }
                  setExcel({});
                  return;  // Stop execution if duplicates are found
                } else {
                  setMainScreen("headerSelectScreen")
                }
              } else {
                toast.error("Excel is empty.");
                // setSelectedFile(null)
              }
            })
            .catch((error) => {
              console.error("Error loading Excel file:", error);
              toast.error("Error loading Excel file. Please try again.");
            });
        } else {
          toast.error("Unsupported file type");
        }
      }
    };
    if (excel.name.includes(".")) {
      fileReader.readAsArrayBuffer(excel);
    } else {
      fileReader.readAsText(excel);
    }
  }
};
useEffect(() => {
  if (excel?.name && excel !== undefined && excel !== null) {
    addYourData();
  }
}, [excel]);


const handleUploadButtonClick = () => {
  if (selectedHeaders?.length === 0) {
    toast.error("Please select at least one header.")
  } else {
    if (excel) {
      const formData = new FormData();
      formData.append('file', excel);
      for (let index = 0; index < selectedHeaders?.length; index++) {
        formData.append('selectedHeaders', selectedHeaders[index])
      }
      const firstMatch = oldHeaders?.every(value => 
        selectedHeaders?.some(i => {
          const stringValue = String(i);
          const lowerCaseValue = stringValue.toLowerCase().trim();
      
          // Compare with the lowercase version of value
          return lowerCaseValue && lowerCaseValue === value.toLowerCase().trim()
        })
    );
    if(firstMatch === true){
        setMainScreen('')
      }else{
        if(firstMatch === false){
          toast.error('some headers are missing')
        }
      }
    } else {
      alert("Please select a file to upload");
    }
  }
};



  return (
    <>
    <div
      className={` bg-black fixed top-0 left-0 right-0 w-full h-full z-40 bg-opacity-80 flex justify-center items-center`}
    >
      <div className="w-[100%] h-[100%] relative flex justify-center items-center">
        <div
          onClick={() => {
            setAddExcelModel(false);
          }}
          className="w-[100%] h-[100%] absolute opacity-[0.7] top-0 left-0 z-[1]"
        ></div>
        <div className=" min-w-[450px] p-[20px] bg-[#272836] flex flex-col items-center z-[1] rounded-[8px]">
          <img
            src={plus_icon}
            alt=""
            onClick={() => {
              setAddExcelModel(false);
            }}
            className="cursor-pointer rotate-45 self-end"
          />
          <div className="w-[100%]">
            <div className="w-[100%] relative">
              <label
                htmlFor="campaign"
                className="font-Inter text-[14px] font-semibold text-[#fff] tracking-[0.32px] leading-[16.94px]"
              >
                Excel Name <span className="text-[#FF5364]">*</span>
              </label>
              <div className=" relative">
                <input
                  name="excelName"
                  className=" w-full rounded-[6px] border border-[#3B3B4F]  bg-transparent text-[#ABABC0] placeholder:text-[#ABABC0] py-[16.5px] mt-[10px] pl-[10px] pr-[33px] text-[14px] outline-none font-Inter font-normal tracking-[0.48] leading-[16.94px]"
                  placeholder="Enter excel name"
                  maxLength={20}
                  type="text"
                  autoComplete="off"
                  value={excelName}
                  onChange={(e) => {
                    setExcelName(e.target.value);
                  }}
                  autoFocus
                ></input>
                <p className="text-[12px] pt-1 absolute bottom-[-18px] leading-[14.52px] font-medium text-[#FF5364]">
                  {excelNameError ? excelNameError : ""}
                </p>
              </div>
              {excelNameError?.length > 0 && (
                <img
                  src={exclamation_mark}
                  alt="*"
                  className="absolute top-[50px] right-[10px] w-[20px]"
                />
              )}
            </div>
            <div className="w-[100%] relative mt-[30px]">
              <label
                htmlFor="Excel"
                className="font-Inter font-semibold text-[14px] text-[#fff] tracking-[0.32px] leading-[16.94px]"
              >
                Excel <span className="text-[#FF5364]">*</span>
              </label>
              <div
                className={`mt-[10px] py-[8.5px] px-[8px] rounded-[6px] border border-[#3B3B4F] transition-all w-[100%] flex items-center `}
              >
                <input
                  onChange={(e) => {
                    setExcel(e.target.files[0]);
                  }}
                  ref={excelinputRef}
                  type="file"
                  name="Excel"
                  accept=".xlsx"
                  autoFocus
                  placeholder="Enter Folder Name Here..."
                  className={`absolute w-[100%] h-[54%] top-[38px] left-[0px] p-[10px] cursor-pointer opacity-0 rounded-[3px]`}
                />
                <p className="h-[100%] text-[#ABABC0] bg-[#3B3B4F] flex p-[8px] rounded-[3px] items-center font-Inter font-normal text-[14px] leading-[16.94px] tracking-[0.48px] mr-[10px]">
                  <img src={excel_upload} alt="video" className="mr-[10px]" />
                  Upload Excel
                </p>
                {excel.name && <img
                src={round_clear}
                alt=""
                onClick={() => {
                  setExcel({});
                  setselectedHeaders([])
                  if (excelinputRef.current) {
                    excelinputRef.current.value = ''; // Reset the input using the ref
                }
                }}
                className="cursor-pointer absolute w-[17px] top-[52px] right-[35px]"
              />}
                {excel.name === undefined ? (
                  ""
                ) : (
                  <span className="font-Inter font-normal text-[14px] leading-[16.94px] tracking-[0.48px] text-[#ABABC0]">
                    {String(excel.name)?.length < 20
                      ? excel.name
                      : `${excel.name.substring(0, 20)}...`}
                  </span>
                )}
                {error?.length > 0 && (
                  <img
                    src={exclamation_mark}
                    alt="*"
                    className="absolute top-[50px] right-[10px] w-[20px]"
                  />
                )}
              </div>
              {error && (
                <p className="text-[12px] absolute bottom-[-18px] pt-1 leading-[14.52px] font-medium text-[#FF5364]">
                  {error}
                </p>
              )}
            </div>
            {excel?.name && <button className=" text-[#ababc0] text-[14px] underline" onClick={() => setMainScreen("headerSelectScreen")}>Edit Headers</button>}

            <div className="w-[100%] flex justify-center items-center mt-[30px]">
              <button
                className="rounded-[6px] border-[1px] py-[11px] px-[35px] text-[#FFFFFF] border-[#5446D0] font-Inter font-medium text-[14px] min-w-[130px] leading-[16px] tracking-[0.48px]"
                onClick={() => {
                  setAddExcelModel(false);
                }}
              >
                Cancel
              </button>
              <button
                className="rounded-[6px] border-[1px] py-[11px] px-[35px] text-[#FFFFFF] bg-[#5446D0] border-[#5446D0] font-Inter font-medium text-[14px] min-w-[130px] leading-[16px] tracking-[0.48px] ml-[12px]"
                onClick={() => {
                  UploadExcel();
                }}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
      {/* select excel header */}
      {mainScreen === "headerSelectScreen" && (
        <div className=" bg-black fixed top-0 left-0 right-0 w-full h-full z-40 bg-opacity-80 flex justify-center items-center">
          <div className=" bg-[#272836] pb-5 relative">
            <div className="mb-[20px] flex flex-col justify-center items-center">
            <div className="z-[1] p-[15px]  ">
              <p className="text-[#fff] font-Inter font-medium">{'Required Headers'} <span className="text-[#FF5364]">*</span> : <span> {oldHeaders.join(", ")}</span></p> 
              <img
            src={plus_icon}
            alt=""
            onClick={() => {
              setMainScreen('');
            }}
            className="cursor-pointer absolute rotate-45 self-end right-5 top-4"
          />
             </div>
              <div className="relative overflow-x-auto  scrollbar-thin scrollbar-track-[#3B3B4F] scrollbar-thumb-[#5A5A71] min-w-[700px]  max-w-[900px]">
                <table className="w-full border border-[#3B3B4F]">
                  <thead className="text-sm font-Inter font-medium text-white  bg-[#3B3B4F]">
                    <tr>
                      {headers?.map((header, index) => (
                        <th key={index} className="p-[20px]">
                          <div className="flex justify-start items-center gap-[10px]">
                            <input
                              id="myCheckbox"
                              type="checkbox"
                              onChange={() => { selection(header) }}
                              checked={selectedHeaders?.includes(header)}
                            />
                            <p className={`text-[14px] text-left ${header.length > 8 && 'min-w-[100px]'}`}>{header}</p>
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-t border-[#2C2D3C] text-sm font-Inter font-medium text-[#ABABC0] text-left">
                      {firstRow?.map((cell, index) => (
                        <td key={index} className="p-[20px] text-[14px] ">
                          {cell?.toString()?.length > 15 ? cell?.toString().substring(0, 15) + '...' : cell?.toString()}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-center items-center gap-[20px]">
              <button
                className="bg-[#5446D0] rounded py-[8px] w-[150px] outline-none font-Inter font-medium text-sm text-[#FFFFFF]"
                onClick={handleUploadButtonClick}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddExcelModel;
