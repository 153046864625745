import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useCustomSearchParams = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useState(undefined)

    useEffect(() => {
        if (location !== undefined) {
            const searchAsObject = Object.fromEntries(
                new URLSearchParams(location.search)
            );
            Object.keys(searchAsObject).map(item => {
                if (searchAsObject[item].includes(",")) {
                    const splitArray = searchAsObject[item].split(",")
                    searchAsObject[item] = splitArray
                }
            })
            setSearchParams(searchAsObject)
        }
    }, [location])
    return searchParams;
}
