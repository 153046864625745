import { useEffect, useState } from "react"
import { localStorageNames } from "../../configs/constants"
import { useNavigate } from 'react-router-dom'
import LoaderRing from '../ui/common/LoaderRing'

const { __USER_DATA } = localStorageNames
const PublicRoute = ({ children }) => {

    const navigate = useNavigate()
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    useEffect(() => {
        if (localStorage.getItem(__USER_DATA)) {
            setIsLoggedIn(false)
            navigate('/videos')
        } else {
            setIsLoggedIn(true)
        }
    }, [])

    return (
        <div className="w-[100%]">
            {isLoggedIn ? children : <LoaderRing />}
        </div>
    )
}

export default PublicRoute





